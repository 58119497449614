<script src="../../babel.config.js"></script>
<template>
  <div>
    <!--  轮播图-->
    <div class="rotateList">
      <div class="top">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in rotateList" :key="index" data-swiper-autoplay="6000">
            <img :src="window.ipConfig.baseUrl + item.image"  ></div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <!--  磐石动态&合作伙伴-->
    <div class="banner">
      <!--    磐石动态 专栏-->
      <div class="panshDynamic">
        <div class="panshDynamicTit"> 
          <p class="indexTitle">
            磐石动态
            <!-- <img src="../assets/index/title2.png"> -->
          </p>
          <div>
            <el-button class="iconfont icon-zuo" @click="prevPage()"></el-button>
            <el-button class="iconfont icon-you1" @click="nextPage()"></el-button>
          </div>
        </div>
        <ul>
          <li v-for="(item,index) in panshDynamicList[dynamicNum]" :key="index" @click="psdtDialogPush(item)">
            <a>
              <img :src="window.ipConfig.baseUrl + item.image">
              <div>
                <p>{{ item.title }}</p>
                <p>{{ item.content }}</p>
              </div>
            </a>
          </li>
        </ul>

<!--        磐石动态弹出框-->
        <el-dialog :title='psDynamic.title' :visible.sync="dialogTableVisible" :top="`10vh`">
          <p>{{psDynamic.createTime}}</p>
          <div class="dialog_show" v-html="psDynamic.imageAndContent"></div>
        </el-dialog>
      </div>
      <!--    磐石应用 专栏-->
      <div class="cooperation">
        <ul>
          <!-- <li @click="open()"> -->
          <li>
            <!-- <a href="https:www.npms.navimentum.com:8090" target="_blank"> -->
            <a href="https://pms.pansh.com.cn:8801/" target="_blank">
              <div class="cooperatBox">
                <img src="../assets/index/main/middle06.png">
                <!-- <img src="../assets/wb_static/yy1.jpg"> -->
                <div class="cooperatBox-bottom">
                  <hr>
                  <p>磐石经营管理系统</p>
                  <p>面向参与运营人员及渠道合作伙伴开放，磐石全流程运营管理平台，涵盖渠道、工厂（研发）、商机、库存、服务等功能模块</p>
                  <p>
                    <i class="iconfont icon-xiangyou2"></i>
                  </p>
                </div>
              </div>
            </a>
          </li>
          <li>
            <a href="https://service.pansh.com.cn:9000/" target="_blank">
            <!-- <a href="https:www.service.navimentum.com:8090" target="_blank"> -->
              <div class="cooperatBox">
                <img src="../assets/index/main/middle15.png">
                <!-- <img src="../assets/wb_static/yy2.jpg"> -->
                <div class="cooperatBox-bottom">
                  <hr>
                  <p>磐石客户服务平台</p>
                  <p>面向磐石企业级用户提供端对端服务支持，用户可查询、下载许可权限和使用记录，可在线反馈使用问题以及跟踪故障解决进度等，为用户提供一站式快捷服务</p>
                  <p>
                    <i class="iconfont icon-xiangyou2"></i>
                  </p>
                </div>
              </div>
            </a>
          </li>
          <!-- <li @click="pocFormVisible = true">
            <a>
              <div class="cooperatBox">
                <img src="../assets/index/main/middle15.png">
                <div class="cooperatBox-bottom">
                  <hr>
                  <p>POC申请</p>
                  <p>磐石积极参与和推动信创适配，持续打造磐石信创生态圈，支持各种国产办公和行业应用套件，为用户提供更加完善的云桌面解决方案和更加广泛的应用场景。</p>
                  <p>
                    <i class="iconfont icon-xiangyou2"></i>
                  </p>
                </div>
              </div>
            </a>
          </li> -->
        </ul>
        <div style="display: flex;justify-content: center;align-items: center;padding: 1.5% 0;margin-top: 16px;">
          <p class="indexTitle">磐石应用</p>
          <!-- <img src="../assets/index/title1.png"  > -->
        </div>

        

      </div>
    </div>
    <!--  典型案例-->
    <div>
      <p class="littleTitle">
        <img src="../assets/index/titleLine.png"  >
        <!-- <img src="../assets/index/caseTitl.png"  > -->
        <span class="indexTitle">典型案例</span>
        <img src="../assets/index/titleLine.png"  >
      </p>
      <div class="caseBox">
        <div class="caseBoxa">
            <div class="swiper-container">
              <ul class="swiper-wrapper">
                <li class="swiper-slide" v-for="(item,index) in caseList" :key="index" data-swiper-autoplay="8000">
                  <div class="caseLiTop">
                    <img :src="window.ipConfig.baseUrl + item.image">
                    <p>{{ item.type }}</p>
                  </div>
                  <div class="caseLiBottom">
                    <div>
                      <p>{{ item.company }}</p>
                    </div>
                  </div>
                </li>
              </ul>
              
              <!-- 如果需要分页器 -->
              <!--<div class="swiper-pagination"></div>-->
            </div>
        </div>
        <div class="caseBtn">
          <div class="btns">
            <!-- 如果需要导航按钮 -->
            <div class="swiper-button-prev " id="btnprev">&lt;</div>
            <div class="swiper-button-next " id="btnnext">&gt;</div>
          </div>
        </div>
      </div>
    </div>
    
    <!--  合作伙伴专栏-->
    <div style="display: block;width: 96%;margin: 21px auto 0px;">
      <p class="littleTitle">
        <img src="../assets/index/titleLine.png"  >
        <!-- <img src="../assets/index/cooperationTitle.png"  > -->
        <span class="indexTitle">合作伙伴专栏</span>
        <img src="../assets/index/titleLine.png"  >
      </p>
      <div class="bottom">
        <div class="swiper-container" style="text-align: center">
          <ul class="swiper-wrapper">
            <li class="swiper-slide" v-for="(item,index) in cooperationRotateList" :key="index" data-swiper-autoplay="5000">
              <img :src="window.ipConfig.baseUrl + item.image"   style="max-height: 200px">
              <!-- <div class="cooperationDesc">
                <div class="coperCent">
                  <p>{{item.content}}</p>
                  <img :src="window.ipConfig.baseUrl + item.image" class="corImg"  style="margin:0 auto;">
                </div>
              </div> -->
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 推广弹窗 -->
    <el-dialog
      class="dialogPush"
      :visible.sync="detShow"
      :title="checkinterval + 'S'"
      width="45%"
      :top="`10vh`">
      <h4 style="font-size: 24px;text-align: center;">{{detPush.title}}</h4>
      <div class="dynamicPushShow" v-html="detPush.imageAndContent">
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {psDynamic,psCaseList,getPushDynamic,getCooperations,getPCBanners} from '@/api'
import Swiper from 'swiper'
import _ from 'lodash'
export default {
  name: "index",
  data() {
    return {
      // 磐石动态数据
      psDynamic:{},
      // 磐石动态详细数据
      psdtDialog:[{title:''},{time:''},{img01:'',para01:'',img02:'',para02:'',img03:'',para03:'',img04:'',para04:'',img05:'',para05:''}],
      // 显示哪条动态详情
      psdtDialogIdx:0,
      // 详情框展示
      dialogTableVisible: false,
      // 动态激活坐标
      inde:0,
      // 典型案例数据
      rotateList:[],
      // 磐石动态列表数据
      panshDynamicList: [],
      // 磐石应用数据
      cooperatList: [
        {imgSrc:require('../assets/index/main/middle06.png'),title:'PMS',desc:'磐石经营管理系统旨在提高企业经营管理效益，对企业所拥有的人员、产品等综合资源进行平衡和优化管理，协助企业各管理部门围绕市场导向展开业务活动，提高企业的核心竞争力，从而取得更好的经济效益。'},
        {imgSrc:require('@/assets/index/main/middle15.png'),title:'POC申请',desc:'磐石积极参与和推动信创适配，持续打造磐石信创生态圈，支持各种国产办公和行业应用套件，为用户提供更加完善的云桌面解决方案和更加广泛的应用场景。'}],
      caseList1:[],
      // 合作伙伴数据
      cooperationRotateList:[],
      // 案例列表
      caseList:[],
      // 定义swiper
      mySwiper:{},
      mySwiperBottom:{},
      mySwiperCase:{},
      // 动态数量，方便整理换页功能
      dynamicNum:0,
      // 推广动态
      detShow:false,
      checkinterval:13,
      detPush:{},
      pushTimer:{},
    }
  },
  computed:{
    window: function () {
      // `this` 指向 vm 实例
      return window
    }
  },
  created(){
    if (this._isMobile()) {
      // 跳转手机页面
      this.$router.push('mob')
    }
  },
  mounted() {
    // 页面初始化时获取各项数据
    this.getPsDynamic(),
    this.getCooperations(),
    this.getPcBanner(),
    this.getCaseList()
    // 延时加载swiper。防止展示不正确
    setTimeout(() => {
      // 轮播图
      var mySwiper = new Swiper('.top .swiper-container',{
        loop: true,
        // 如果需要分页器
        pagination: '.top .swiper-pagination',
        //如果需要自动切换轮播图
        autoplay: {
          delay: 1000,//时间 毫秒
          disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
        },
        paginationClickable :true,
        autoplayDisableOnInteraction : false
      })
      // 案例
      var mySwiperBottom = new Swiper('.caseBox .swiper-container',{
        loop: true,
        // 如果需要分页器
        // pagination: '.caseBox .swiper-pagination',
        // 如果需要前进后退按钮
        nextButton: '.caseBox .swiper-button-next',
        prevButton: '.caseBox .swiper-button-prev',
        slidesPerView: '4',      //设置slider容器能够同时显示的slides数量
        //如果需要自动切换轮播图
        autoplay: {
          delay: 1000,//时间 毫秒
          disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
        },
        autoplayDisableOnInteraction : false
      });
      // 合作伙伴
      var mySwiperCase = new Swiper('.bottom .swiper-container',{
        loop: true,
        // 如果需要分页器
        pagination: '.bottom .swiper-pagination',
        slidesPerView: '4',      //设置slider容器能够同时显示的slides数量
        //如果需要自动切换轮播图
        autoplay: {
          delay: 1000,//时间 毫秒
          disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
        },
        autoplayDisableOnInteraction : false
      });
      //鼠标覆盖停止自动切换
      mySwiper.container[0].onmouseover=function(){
        mySwiper.stopAutoplay();
      }
      //鼠标移出开始自动切换
      mySwiper.container[0].onmouseout=function(){
        mySwiper.startAutoplay();
      };
      //鼠标覆盖停止自动切换
      mySwiperBottom.container[0].onmouseover=function(){
        mySwiperBottom.stopAutoplay();
      }
      //鼠标移出开始自动切换
      mySwiperBottom.container[0].onmouseout=function(){
        mySwiperBottom.startAutoplay();
      };
      
      mySwiperCase.container[0].onmouseover=function(){
        mySwiperCase.stopAutoplay();
      }
      //鼠标移出开始自动切换
      mySwiperCase.container[0].onmouseout=function(){
        mySwiperCase.startAutoplay();
      };
    }, 1000);

    setTimeout(() => {
      this.detShow = true
    }, 3000);
    this.pushTimer = setInterval(() => {
      this.checkinterval -= 1
      if(this.checkinterval == 0){
        this.detShow = false
        clearInterval(this.pushTimer)
      }
    }, 1000);
    
  },
  methods:{
    
    // 判断移动|PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    // 获取合作伙伴
    async getCooperations(){
      const result = await getCooperations()
      this.cooperationRotateList = result.data
    },
    // 获取Banner
    async getPcBanner(){
      const result = await getPCBanners(0)
      this.rotateList = result.data
    },
    // 获取磐石动态
    async getPsDynamic(){
      const result = await psDynamic();
      let result1 = _.chunk(result.data, 4)
      // 判断最后一页是否满4条
      if(result1[result1.length - 1].length < 4){
        let randMax = 4 - result1[result1.length - 1].length
        // 从前一页获取条数补齐4条
        for (let index = 0; index < randMax; index ++) {
          result1[result1.length - 1].unshift(result1[result1.length - 2][3 - index])
        }
      }
      const pushDyn = await getPushDynamic()
      this.detPush = pushDyn.data[0]
      /* for (const key in result.data) {
        if (result.data[key].openFlag == 0) {
          this.detPush =result.data[key]
        }
      } */
      this.panshDynamicList = result1
    },
    // 动态前一页
    prevPage(){
      if(this.dynamicNum == 0){
        // 跳转到最后一页
        this.dynamicNum = this.panshDynamicList.length - 1
      }else{
        this.dynamicNum -= 1
      }
    },
    // 动态后一页
    nextPage(){
      if(this.dynamicNum == this.panshDynamicList.length - 1){
        // 跳转到第一页
        this.dynamicNum = 0
      }else{
        this.dynamicNum += 1
      }
    },
    // 弹框展示动态详情
    psdtDialogPush(item){
      this.dialogTableVisible = true;
      this.psDynamic = item
    },
    // 提示
    open() {
      this.$message('该栏目正在维护中...');
    },
    // 获取案例
    async getCaseList(){
      const result = await psCaseList();
      this.caseList = result.data
    }
    
  }
}
</script>

<style scoped>
/*公共样式*/
* {
  font-family: SourceHanSansSC-bold;
  list-style: none;
}
a {
  color: #000;
  text-decoration: none;
}
/*页面样式*/
.dialogPush >>> .el-dialog__header{text-align: right;padding: 20px 40px 10px;}
.dialogPush >>> .el-dialog__title{position: relative;top:-4px;}
.dialogPush >>> .el-dialog__body{padding:0px 20px 30px;}
.dynamicPushShow{height: 500px;overflow: auto;margin-top: 24px;}
.dynamicPushShow >>> p{text-indent: 2em;font-size: 16px;line-height: 28px;width: 94%;margin: 2% auto;word-wrap: break-word;white-space: break-spaces;text-align: justify;}
.dynamicPushShow >>> p img{max-width: 86%;height: auto;display: block;margin: 0 auto;text-align: center;}
.top .swiper-slide img {
  width: 100%;
}
.swiper-container {
  width: 100%;
}
.swiper-containerTop  >>> .swiper-pagination-bullet,
.swiper-containerBottom >>> .swiper-pagination-bullet{
  background-color: #ddd;
  opacity: 1;
}
.swiper-containerTop >>> .swiper-pagination-bullet-active{
  background-color: #f77c09;
}
.swiper-containerBottom >>> .swiper-pagination-bullet-active{
  background-color: #333;
}
.caseBox{
  position: relative;
}
.caseBoxa{
  width: 90%;
  margin:0 auto;
}
.btns{
  position: relative;
}
.caseBtn{
  position: absolute;
  left: 2%;
  top: calc(50% - 10px);
  width: 96%;
}
#btnprev,#btnnext{
  top:0px;
  width: 30px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  font-size: 18px;
  color: #000;
  background: #f8f8f8;

}
.indexTitle{
  font-size: 34px;
  font-weight: 600;
}
/*水平分块标题*/
.littleTitle {
  padding: 32px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.caseTitle{
  padding: 0 10% 14px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.banner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /*align-items: center;*/
  width: 100%;
  margin-top: 50px;
  background: url(../assets/index/bg.png) no-repeat;
  background-size: 100% 99%;
  padding-bottom: 60px;
}

/*磐石动态专栏*/
.panshDynamic{
  width: 49%;
  margin-left: 1%;
}
.cooperation {
  width: 49%;
}
.caseBox .cover{
  transform: perspective(600px) rotateX(-45deg);
  transform-origin: top;
  position: absolute;
  bottom: 0px;
  color: white;
  background-color: rgba(51, 51, 51,0.8);
  opacity: 0;
  width: 90%;
  height: 100%;
  transition: all 0.3s;
  padding: 0 5%;
  text-indent: 2em;
  font-size:14px;
  display: flex;
  align-items: center;
}
.panshDynamic .panshDynamicTit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 60px;
}
.panshDynamic .panshDynamicTit >p:first-child {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
}
.panshDynamic .panshDynamicTit >div{
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 1%;
}
.panshDynamic .panshDynamicTit >div button{
  font-size: 14px;
  padding: 4px 10px;
}
.panshDynamic ul li a {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #fff;
}
.panshDynamic ul li{
  transition: all 0.3s;
  cursor: pointer;
}
.panshDynamic ul li:nth-child(even) {
  background-color: rgba(245,245,245);
}
.panshDynamic ul li a img{width: 30%;}
/*媒体查询改   图片样式*/
/*.panshDynamic ul li a img {*/
/*  width: 33%;*/
/*}*/
.corImg{width: 150px;}
@media screen and (min-width: 950px){
  .cooperationDesc img{display: none;}
  .cooperatBox .cooperatBox-bottom p:nth-child(3){font-size: 14px;}
  .panshDynamic .panshDynamicTit{margin-bottom: 20px;}
  .caseBox .cover{line-height: 24px;}
  .bottom ul li{padding-bottom: 40px;}
}
@media screen and (min-width: 1000px){
}
@media screen and (min-width: 1050px){
  .pscpBox >li{margin-right: 2%;}
}
@media screen and (min-width: 1100px){
  
}
@media screen and (min-width: 1150px){
.panshDynamic ul li a img{width: 28%;}
  
}
@media screen and (min-width: 1200px){
  
}
@media screen and (min-width: 1250px){
  .caseBox .cover{font-size: 16px;line-height: 30px;}
}
@media screen and (min-width: 1300px){
  .cooperationDesc img{display: block;}
  
}
@media screen and (min-width: 1350px){
  
}
@media screen and (min-width: 1400px){
  /* .panshDynamic ul li a img {
    width: 32%;
  } */
}@media screen and (min-width: 1500px){
  .corImg{width: 200px;}

  /* .panshDynamic ul li a img {
    width: 32%;
  } */
}@media screen and (min-width: 1670px){
}@media screen and (min-width: 1750px){
}@media screen and (min-width: 1850px){
}
.panshDynamic ul li a div {
  padding-left: 15px;
  flex: 2;
}
.panshDynamic ul li a div >p:first-child {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 5px;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
}
.panshDynamic ul li a div >p:last-child {
  font-size: 14px;
  line-height: 18px;
  height: 36px;
  overflow: hidden;
  width: 85%;
  text-align: justify;
}
/*磐石动态————弹出框样式*/
.panshDynamic >>> .el-dialog__title {
  line-height: 24px;
  font-size: 20px;
  color: #303133;
  font-weight: bold;
}
.panshDynamic >>> .el-dialog__body {
  padding: 10px 20px 30px;
}
>>> .dialog_show{
  height: 600px;
  overflow: auto;
}
/*图片样式*/
>>> .dialog_show img{
  max-width: 70%;
  height: auto;
  display: block;
  margin: 0 auto;
  text-align: center;
}

/*文字样式*/
>>> .dialog_show p{
  text-indent: 2em;
  font-size: 16px;
  line-height: 28px;
  width: 80%;
  margin: 2% auto;
  word-wrap:break-word;
  white-space:break-spaces;
  text-align: justify;
}


/*磐石体系*/
.cooperation ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}
.cooperation ul li{
  width: 49%;
  margin-left: 0.5%;
}
.cooperation ul li .cooperatBox{
  transition: all 0.3s;
  cursor: pointer;
  position: relative;
  top: 0px;
}
.cooperation ul li img{
  width: 100%;
}
.cooperatBox{
  width: 97%; /*加上 此条会使li右侧有点空白！！*/
  background-color: #eeeff0;
  border-radius: 5px;
  overflow: hidden;
}
.cooperatBox .cooperatBox-bottom{
  padding:10px 20px;
}
.cooperatBox .cooperatBox-bottom >hr{
  color: red;
  width: 30px;
  border: 2px solid red;
}
.cooperatBox .cooperatBox-bottom p:nth-child(2){
  font-size: 20px;
  font-weight: bold;
  padding: 5px 0;
  color: #333;
}
.cooperatBox .cooperatBox-bottom p:nth-child(3){
  margin-top: 8px;
  font-size: 14px;
  min-height: 91px;
  line-height: 22px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-align: justify;
}
/*两个右对齐的按钮合并样式*/
.cooperatBox .cooperatBox-bottom p:last-child,
.cooperation .cooperationMore{
  text-align: right;
}
.cooperatBox .cooperatBox-bottom i{
  font-size: 32px;
  color: #969696;
}

/*典型案例*/
.caseBox{
  margin: 0 3%;
}
.caseBox >i{
  font-size: 48px;
  color: #718ca2;
  cursor: pointer;
}
.caseBox ul li{
  margin: 0 0.55%;
  width: 23.9%!important;
  background-color: #f3f3f3;
  position: relative;
  overflow: hidden;
}
.caseBox ul li img{
  width: 100%;
}

.caseBox .caseLiTop{
  position: relative;
}
.caseBox .caseLiTop p {
  position: absolute;
  bottom: 3.8px;
  left: 0px;
  padding: 8px 12px;
  font-size: 19px;
  color: #fff;
  background-color: rgba(74, 132, 181,0.5);
}
.caseBox .caseLiBottom{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /*height: 110px;*/
  padding: 16px 12px;
}
.caseBox .caseLiBottom >div:first-child >p:first-child{
  font-size: 19px;
  font-weight: bold;
  color: #83b1dc;
  padding-bottom: 10px;
}


/*合作伙伴专栏*/
.bottom ul li > img{
  width: 90%;
  height: auto;
  transition: all 0.5s;
  backface-visibility:hidden;

}

.coperCent{
  position: absolute;
}


</style>