<template>
<div>
  <el-dialog title="问题反馈" :visible.sync="dialogFormVisible" append-to-body :before-close="fkHandleClose">
    <el-form :model="form" ref="ztform" :rules="rules">
      <el-form-item label="标题" :label-width="formLabelWidth" prop="title">
        <el-input v-model="form.title" placeholder="请输入标题"></el-input>
      </el-form-item>
      <el-form-item label="类别" :label-width="formLabelWidth" prop="type">
        <el-select v-model="form.type" placeholder="请选择类别" style="width: 100%;">
          <el-option label="显示界面" value="显示界面"></el-option>
          <el-option label="桌面连接" value="桌面连接"></el-option>
          <el-option label="卡顿" value="卡顿"></el-option>
          <el-option label="外设" value="外设"></el-option>
          <el-option label="网络" value="网络"></el-option>
          <el-option label="云终端" value="云终端"></el-option>
          <el-option label="软件" value="软件"></el-option>
          <el-option label="权限" value="权限"></el-option>
          <el-option label="其他" value="其他"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="问题描述" :label-width="formLabelWidth" prop="question">
        <el-input type="textarea" :rows="2" placeholder="请输入问题描述" v-model="form.question"></el-input>
      </el-form-item>
      <el-form-item label="联系人" :label-width="formLabelWidth" prop="contacts">
        <el-input v-model="form.contacts" placeholder="请输入联系人"></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="24">
          <el-form-item label="电话" :label-width="formLabelWidth" prop="tel">
            <el-input v-model="form.tel" placeholder="请输入电话">
              <!-- <el-button slot="append" @click="getCode" :disabled="btnContent != '获取验证码'">{{btnContent}}</el-button> -->
            </el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="验证码" :label-width="formLabelWidth" prop="telCode">
            <el-input v-model="form.telCode" placeholder="请输入短信验证码">
            </el-input>
          </el-form-item>
        </el-col> -->
      </el-row>
      <el-form-item label="公司名称" :label-width="formLabelWidth" prop="company">
        <el-input v-model="form.company" placeholder="请输入公司名称"></el-input>
      </el-form-item>
      <el-form-item label="邮箱" :label-width="formLabelWidth" prop="email">
        <el-input v-model="form.email" placeholder="请输入邮箱"></el-input>
      </el-form-item>
      <el-form-item label="上传图片" :label-width="formLabelWidth">
        <el-upload
            ref="imgupload"
            action="/api/upload/saveimgPath"
            list-type="picture-card"
            :on-success="handleSuccess"
            :on-preview="handlePictureCardPreview"
            :before-upload="beforeAvatarUpload">
          <i class="el-icon-plus"></i>
        </el-upload>
        
      </el-form-item>

    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">取 消</el-button>
      <el-button type="primary" @click="submitForm('ztform')">提 交</el-button>
    </div>
  </el-dialog>
  <!-- 图片预览dialog -->
  <el-dialog :visible.sync="prevdialogVisible">
    <img width="100%" :src="dialogImageUrl">
  </el-dialog>
</div>
</template>

<script>
import {postForm} from '@/api'
export default {
  name:'FormDialog',
  data(){
    // 表单手机号验证规则
    var reg = (rule, value, callback) => {
      var regExp = new RegExp("^[0-9]{11}$");
      if (regExp.test(value)) {
        callback();
      }
      else {
        callback(new Error("请输入正确的手机号码"));
      }
    };
    // 表单邮箱验证
    var eamilreg = (rule, value, callback) => {
      const regEmail =  /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regEmail.test(value)) {
        callback();
      }
      else {
        callback(new Error("请输入正确的邮箱"));
      }
    }
    return{
      formLabelWidth: "120px",
      dialogImageUrl: "",
      form: {
        // 标题
        title: "",
        // 类型
        type: "",
        // 问题描述
        question: "",
        // 联系人
        contacts: "",
        // 电话
        tel: "",
        // 公司
        company: "",
        // 邮箱
        email: "",
        flag: 0,
        reply: 0,
        // 图片列表
        listAnswer: []
      },
      // 表单验证规则
      rules: {
        title: [
          { required: true, message: "请输入问题标题", trigger: "blur" }
        ],
        type: [
          { required: true, message: "请选择问题类型", trigger: "blur" }
        ],
        tel: [
          { required: true, message: "请输入电话号码", trigger: "blur" },
          { validator: reg, trigger: "blur" }
        ],
        /* telCode:[
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ], */
        question: [
          { required: true, message: "请输入问题描述", trigger: "blur" },
        ],
        contacts: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: eamilreg, trigger: "blur" }
        ],
      },
      dialogFormVisible: false,
      prevdialogVisible: false,
      CountNums:60,
      btnContent:'获取验证码'
    }
  },
  mounted(){
    this.$bus.$on('showfk',this.showDialog)
  },
  methods: {
    showDialog(){
      this.dialogFormVisible = true
    },
    // 获取验证码倒计时
    CountNum(){
      this.CountNums = 6;
      let count = setInterval(() => {
        this.CountNums -= 1
        this.btnContent = this.CountNums + 'S'
        if(this.CountNums == 0){
          clearInterval(count)
          this.btnContent = '获取验证码'
          this.CountNums = 6
        }
      }, 1000);
    },
    // 接受验证码回调
    getCode(){
      let telRegExp = new RegExp("^[0-9]{11}$");
      if(telRegExp.test(this.form.tel)){
        this.$message.success('验证码已发送，请注意查收！')
        this.CountNum()

      }else{
        this.$message.error('请输入正确的手机号')
        return false
      }
    },
    // 表单关闭回调
    fkHandleClose(done){
      this.$confirm('确认关闭？')
        .then( () => {
          done();
        })
        .catch( () => {});
    },
    // 图片预览
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.prevdialogVisible = true;
    },
    // 图片上传回调
    handleSuccess(response) {
        const pic = {};
        pic.img = response.data.src;
        pic.name = response.data.name;
        pic.title = response.data.title;
        // console.log(pic)
        this.form.listAnswer.push(pic);
    },
    // 点击提交回调
    submitForm(ztform) {
      this.$refs[ztform].validate(async (valid) => {
        if (valid) {
          let result = await postForm(this.form);
          if (result.data == 1) {
            this.$message.success("提交成功!");
            this.dialogFormVisible = false;
            this.form = { title: "", type: "", question: "", contacts: "", tel: "", company: "", email: "", flag: 0, reply: 0, listAnswer: [] };
            this.$refs.imgupload.clearFiles();
          }
          else {
            this.$message.error("提交失败!");
          }
        }
        else {
          this.$message.error("请正确填写内容，*为必填项");
          return false;
        }
      });
    },
    // 图片上传验证
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!');
      }
      return isPNG || isJPG && isLt2M;
    }
  },
}
</script>

<style>

</style>