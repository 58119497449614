import Vue from 'vue'
import VueRouter from "vue-router";
import Page from '../components/layout/page.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/', component: Page,meta: { requiresAuth: true }, children: [
            {path: '/index',name:'index',component: () => import('@/views/index.vue') ,meta:{title:'磐石',content: 'disable'}},
        ]
    },
    //磐石产品栏目
    {path: '/cloudDesktop',name:'cloudDesktop', component: () => import('@/views/panshProduct/cloudDesktop.vue'),meta:{title:'云桌面',content: 'disable'}},
    {path: '/cloudTerminal',name:'cloudTerminal', component: () => import('@/views/panshProduct/cloudTerminal.vue'),meta:{title:'云终端',content: 'disable'}},
    {path: '/cloudApplication',name:'cloudApplication', component: () => import('@/views/panshProduct/cloudApplication.vue'),meta:{title:'应用上云',content: 'disable'}},
    {path: '/yunwei',name:'yunwei', component: () => import('@/views/panshProduct/yunwei.vue'),meta:{title:'视频运维安全管理平台',content: 'disable'}},
    {path: '/psytj',name:'psytj', component: () => import('@/views/panshProduct/psytj.vue'),meta:{title:'磐石一体机',content: 'disable'}},
    {path: '/aqwg',name:'aqwg', component: () => import('@/views/panshProduct/aqwg.vue'),meta:{title:'安全网关',content: 'disable'}},

    //技术支持栏目
    {path: '/techSupport',name:'techSupport', component: () => import('@/views/technicalSupport/techSupport.vue'),meta:{title:'知识库',content: 'disable'}},
    {path: '/domDownload',name:'domDownload', component: () => import('@/views/technicalSupport/domDownload.vue'),meta:{title:'文档中心',content: 'disable'}},
    {path: '/downios',name:'downios', component: () => import('@/views/technicalSupport/DownIOS.vue'),meta:{title:'IOS客户端下载',content: 'disable'}},
    {path: '/softwareDownload',name:'softwareDownload', component: () => import('@/views/technicalSupport/softwareDownload.vue'),meta:{title:'软件下载',content: 'disable'}},
    {path: '/PocPage/:name',name:'pocpage', component: () => import('@/views/technicalSupport/PocPage.vue'),meta:{title:'软件下载',content: 'disable'}},
    {path: '/jrlb',name:'jrlb', component: () => import('@/views/technicalSupport/jrlb.vue'),meta:{title:'兼容列表',content: 'disable'}},
    {path: '/PocSub',name:'PocSub', component: () => import('@/views/technicalSupport/PocSub.vue'),meta:{title:'POC申请',content: 'disable'}},

    // 典型应用
    {path: '/dxyy',name:'dxyy', component: () => import('@/views/dxyy.vue'),meta:{title:'典型应用',content: 'disable'}},
    // 信创融合
    {path: '/ecology',name:'ecology', component: () => import('@/views/Ecology.vue'),meta:{title:'信创融合',content: 'disable'}},
    // 信创生态
    {path: '/xcst',name:'xcst', component: () => import('@/views/xcst.vue'),meta:{title:'信创生态',content: 'disable'}},
    // 渠道体系
    {path: '/qdtx',name:'qdtx', component: () => import('@/views/qdtx.vue'),meta:{title:'渠道体系',content: 'disable'}},
    // 培训认证
    {path: '/psjd',name:'psjd', component: () => import('@/views/psjd.vue'),meta:{title:'培训认证',content: 'disable'}},

    // 手机端
    {path: '/mob',name:'mob', component: () => import('@/views/Moperation/Mobile.vue'),meta:{title:'磐石',content: 'disable'}},
    {path: '/moDomDownload', component: () => import('@/views/Moperation/rightBranch/moDomDownload.vue'),meta:{title:'文档下载',content: 'disable'}},
    {path: '/moPanshProduct',name:'moPanshProduct', component: () => import('@/views/Moperation/rightBranch/moPanshProduct.vue'),meta:{title:'磐石产品',content: 'disable'}},
    {path: '/cloudDesktopMobile',name:'cloudDesktopMobile', component: () => import('@/views/Moperation/production/cloudDesktopMobile.vue'),meta:{title:'云桌面',content: 'disable'}},
    {path: '/cloudApplicationMobile',name:'cloudApplicationMobile', component: () => import('@/views/Moperation/production/cloudApplicationMobile.vue'),meta:{title:'应用上云',content: 'disable'}},
    {path: '/cloudTerminalMobile',name:'cloudTerminalMobile', component: () => import('@/views/Moperation/production/cloudTerminalMobile.vue'),meta:{title:'云终端',content: 'disable'}},
    {path: '/OperationsMobile',name:'OperationsMobile', component: () => import('@/views/Moperation/production/OperationsMobile.vue'),meta:{title:'视频运维安全管理平台',content: 'disable'}},
    {path: '/EcologyMobile',name:'EcologyMobile', component: () => import('@/views/Moperation/EcologyMobile.vue'),meta:{title:'信创融合',content: 'disable'}},
    {path: '/DynamicMobile',name:'DynamicMobile', component: () => import('@/views/Moperation/DynamicMobile.vue'),meta:{title:'磐石动态',content: 'disable'}},
    {path: '/dxyyMobile',name:'dxyyMobile', component: () => import('@/views/Moperation/dxyyMobile.vue'),meta:{title:'典型应用',content: 'disable'}},
    {path: '/xcstMobile',name:'xcstMobile', component: () => import('@/views/Moperation/xcstMobile.vue'),meta:{title:'信创生态',content: 'disable'}},
    {path: '/qdtxMobile',name:'qdtxMobile', component: () => import('@/views/Moperation/qdtxMobile.vue'),meta:{title:'渠道体系',content: 'disable'}},
    {path: '/aqwgMobile',name:'aqwgMobile', component: () => import('@/views/Moperation/production/aqwgMobile.vue'),meta:{title:'安全网关',content: 'disable'}},
    {path: '/psytjMobile',name:'psytjMobile', component: () => import('@/views/Moperation/production/psytjMobile.vue'),meta:{title:'磐石一体机',content: 'disable'}},

    
]

const router = new VueRouter({
    // mode:'history',
    routes // (缩写) 相当于 routes: routes
})

export default router