// 当前这个模块：API统一管理
import requests from "./request";

// 接口
// poc申请提交
export const postPoc = data => requests({url:'psApplicationDtPoc/add',method:'post',data}); 

// 问题返回提交
export const postForm = data => requests({url:'psQuestion/save',method:'post',data})

// 获取知识库头部的图标列表
export const getKnowTitleLists = () => requests({url:'psType/list',method:'get'})

// 获取知识库列表
export const getKnowList = (pageCurrent,pageTotal) => requests({url:`psQuestion/list?pageCurrent=${pageCurrent}&pageTotal=${pageTotal}`,method:'get'})

// 按分类查看列表
export const getListByType = (type,pageCurrent,pageTotal) => requests({url:`psQuestion/list?type=${type}&pageCurrent=${pageCurrent}&pageTotal=${pageTotal}`,method:'get'})

// 查询功能展示知识列表
export const searchKnowList = (title,pageCurrent,pageTotal) => requests({url:`psQuestion/list?title=${title}&pageCurrent=${pageCurrent}&pageTotal=${pageTotal}`,method:'get'})

// 查看问题详情
export const knowDeatil = (id) => requests({url:`psQuestion/list?id=${id}`,method:'get'})

// 知识库我的问题功能
export const telKnowList = (tel,pageCurrent,pageTotal) => requests({url:`psQuestion/list?tel=${tel}&pageCurrent=${pageCurrent}&pageTotal=${pageTotal}`,method:'get'})

// 获取首页磐石动态列表
export const psDynamic = () => requests({url:'psDynamic/list',method:'get'})
export const psCaseList = () => requests({url:'psCase/list',method:'get'})

// 获取文档下载列表
export const getPsDoc = (name) => {
  if(name){
    return requests({url:`psDocumentAndSoftware/list?name=${name}`,method:'get'})
  }else{
    return requests({url:'psDocumentAndSoftware/list',method:'get'})
  }
}

// 提交-在线联系
export const postContext = data => requests({url:`psOnlineContact/save`,method:'post',data})

// 获取合作伙伴
export const getCooperations = () => requests({url:'psCooperativePartner/list',method:'get'})

// 获取PC端轮播图
export const getPCBanners = type => requests({url:`psRotation/list?type=${type}`,method:'get'})

// download_test
export const test = () => requests({url:`upload/download`,method:'get'})
// login_test
export const Login = (username,password) => requests({url:`login?username=${username}&password=${password}`,method:'post'})

//获取我的poc
export const geMyPoc = (tel) => requests({url:`psApplicationDtPoc/list?tel=${tel}`,method:'get'})

//验证下载资格
export const getReg = (tel) => requests({url:`psApplicationDtPoc/list?tel=${tel}`,method:'get'})

//提交下载请求
export const postDownLoad = (data) => requests({url:`psDownload/add`,method:'post',data})
export const postDownLoadDount = (data) => requests({url:`psDocumentAndSoftware/edit`,method:'put',data})

//获取产品展示列表
export const getProductsShowList = () => requests({url:`psProduct/list?pageCurrent=1&pageTotal=100`,method:'get'})

// 按条件搜索适配列表
export const searchSpShowList = (name) =>{
  if(name){
    return requests({url:`psProductFit/list?name=${name}`,method:'get'})
  }else{
    return requests({url:'psProductFit/list',method:'get'})
  }
}
export const getspShowList = () => requests({url:'psProductFit/list',method:'get'})
//获取适配分类信息
export const getspTypeList = () => requests({url:'psProductFit/listTypeName',method:'get'})

// 获取视频列表
export const getVideo = () => requests({url:'psVideo/list',method:'get'})

// 提交认证申请
export const postReg = (data) => requests({url:'psVideoTrain/add',method:'post',data})

// 手机号测试
export const phoneTest = (phone) => requests({url:`sms/SqmSMS?phone=${phone}`,method:'get'})

// 认证码验证
export const RegCode = (authenticationCode) => requests({url:`sms/authenticationCode?authenticationCode=${authenticationCode}`,method:'get'})

// 新增认证申请
export const addRegForm = (data) => requests({url:'psTrainApplication/add',method:'post',data})

// 視頻播放量
export const addVideoNum = (data) => requests({url:`psVideo/addNumber`,method:'put',data})
// 获取推送文章
export const getPushDynamic = () => requests({url:`psDynamic/list?openFlag=0`,method:'get'})