let prefixUrl = process.env.NODE_ENV === 'production' ? '/' : '/dist/';
// 下载本地资源文件
// file 文件全称包含文件扩展名
// fileName下载下来的文件名称（自定义）
function downloadLocalFile(file, fileName) {
    // console.log('prefixUrl：',prefixUrl)

    let a = document.createElement('a');
    // 路径中'/'为根目录，即index.html所在的目录
    a.href = prefixUrl + 'package/' + file;
    // 下载下来的文件名
    a.download = fileName;
    // 添加点击
    a.click();
}

export default downloadLocalFile