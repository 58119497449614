<template>
  <!-- 磐石应用poc申请弹出框 -->
    <el-dialog title="磐石云桌面poc申请" :width="`60%`" :visible.sync="pocFormVisible" :before-close="pocHandleClose" class="poc">
      <el-form :model="poc" ref="pocForm" :rules="pocRules" class="poc" label-width="130px">
        <el-form-item label="客户名称" prop="customerName">
          <el-input v-model="poc.customerName" clearable placeholder="请输入客户姓名"></el-input>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="联系人" prop="contacts">
              <el-input v-model="poc.contacts" clearable placeholder="请输入联系人姓名"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号" prop="tel">
              <el-input v-model="poc.tel" clearable placeholder="请输入手机号码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model="poc.email" placeholder="请输入邮箱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="用户数量" prop="userQty">
              <el-input v-model.number="poc.userQty" type="number"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="项目概况" prop="projectContent">
          <el-input v-model="poc.projectContent" type="textarea" clearable></el-input>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="计算层资源" prop="computerResources">
              <el-select v-model="poc.computerResources" placeholder="请选择计算层资源">
                <el-option label="Vmware Esxi" value="Vmware Esxi"></el-option>
                <el-option label="Citrix Xenserver" value="Citrix Xenserver"></el-option>
                <el-option label="超融合（品牌）" value="超融合（品牌）"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="其他计算层资源" v-show="poc.computerResources =='其他'" prop="otherComputerResources">
              <el-input v-model="poc.otherComputerResources" clearable placeholder="请输入其他计算层资源"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="访问方式" prop="accessModeList">
              <el-select v-model="poc.accessModeList" multiple placeholder="请选择访问方式">
                <el-option label="局域网" value="局域网"></el-option>
                <el-option label="互联网" value="互联网"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="应用场景" prop="appScenarioList">
              <el-select v-model="poc.appScenarioList" multiple collapse-tags placeholder="请选择应用场景">
                <el-option label="日常办公" value="日常办公"></el-option>
                <el-option label="专业图形、视频处理" value="专业图形、视频处理"></el-option>
                <el-option label="软件研发" value="软件研发"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="其他应用场景" v-show="poc.appScenarioList.indexOf('其他') != -1" prop="otherAppScenario">
              <el-input v-model="poc.otherAppScenario" clearable placeholder="请输入应用场景"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="桌面类型" prop="desktopTypeList">
              <el-select v-model="poc.desktopTypeList" multiple collapse-tags placeholder="请选择桌面类型">
                <el-option label="Win7" value="Win7"></el-option>
                <el-option label="Win10" value="Win10"></el-option>
                <el-option label="Linux" value="Linux"></el-option>
                <el-option label="麒麟" value="麒麟"></el-option>
                <el-option label="统信" value="统信"></el-option>
                <el-option label="其他" value="其他"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-show="poc.desktopTypeList.indexOf('其他') != -1" label="其他桌面类型" prop="otherDesktopType">
              <el-input v-model="poc.otherDesktopType" placeholder="请输入桌面类型"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="终端类型" prop="terminalTypeList">
          <el-select v-model="poc.terminalTypeList" multiple placeholder="请选择终端类型">
            <el-option label="瘦客户机" value="瘦客户机"></el-option>
            <el-option label="传统PC" value="传统PC"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="其他">
          <el-input v-model="poc.other" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="pocFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitPoc('pocForm')">提 交</el-button>
      </div>
    </el-dialog>
</template>

<script>
import {postPoc} from '@/api'
export default {
  name:"poc",
  mounted(){
    this.$bus.$on('showPoc',this.showPoc)
  },
  data(){
    // poc表单邮箱验证
    var eamilreg = (rule, value, callback) => {
      const regEmail =  /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/
      if (regEmail.test(value)) {
        callback();
      }
      else {
        callback(new Error("请输入正确的邮箱"));
      }
    }
    // poc表单手机号验证规则
    var reg = (rule, value, callback) =>{
      var regExp = new RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/);
      if (regExp.test(value)) {
        callback()
      } else {
        callback(new Error('请输入正确的手机号码'));
      }
    }
    // poc表单用户人数验证
    var usersNumberReg = (rule, value, callback) => {
      if(value < 1){
        callback(new Error('用户人数最少为1人'));
      }else{
        callback()
      }
    }
    return{
      // poc申请dialog显示隐藏
      pocFormVisible:false,
      // poc申请数据
      poc:{
        // 用户名称
        customerName:'',
        // 联系人
        contacts:'',
        // 电话
        tel:'',
        // 邮箱
        email:'',
        // 用户数量
        userQty:1,
        // 项目概况
        projectContent:'',
        // 计算层资源
        computerResources:'',
        // 计算层资源(其他)
        otherComputerResources:'',
        // 访问方式
        accessModeList:[],
        // 应用场景
        appScenarioList:[],
        // 其他应用场景
        otherAppScenario:'',
        // 桌面类型
        desktopTypeList:[],
        // 其他桌面类型
        otherDesktopType:'',
        // 终端类型
        terminalTypeList:[],
        // 其他
        other:''
      },
      // poc申请表单验证规则 
      pocRules:{
        customerName:[
          {required: true, message: '请输入用户名称', trigger: 'blur'}
        ],
        contacts:[
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ],
        tel:[
          {required: true, message: '请输入电话号码', trigger: 'blur'},
          {validator:reg,trigger: 'blur'}
        ],
        userQty:[
          { required: true, message: '用户人数不能为空',trigger: 'blur'},
          {validator:usersNumberReg,trigger: 'blur'}
        ],
        email:[
          { required: true, message: '邮箱不能为空',trigger: 'blur'},
          { validator: eamilreg, trigger: "blur" }
        ],
        projectContent:[
          { required: true, message: '请输入项目概述'}
        ],
        computerResources:[
          { required: true, message: '请选择计算层资源'}
        ],
        otherComputerResources:[
          // { required: true, message: '请输入您使用的计算层资源'},
          // {validator: otherComputerResourcesreg,trigger:'blur'}
        ],
        accessModeList:[
          { required: true, message: '请选择访问方式'},
        ],
        appScenarioList:[
          { required: true, message: '请选择应用场景'},
        ],
        otherAppScenario:[
          // { required: true, message: '请输入应用场景'},
        ],
        desktopTypeList:[
          { required: true, message: '请选择桌面类型'},
        ],
        otherDesktopType:[
          // {required: true, message: '请输入其他桌面类型'}
        ],
        terminalTypeList:[
          {required: true, message: '请选择终端类型'}
        ]
      },
    }
  },
  methods:{
    showPoc(){
      this.pocFormVisible = true
    },
    // poc表单验证
    pocHandleClose(done){
      this.$confirm('确认关闭？')
        .then( () => {
          done();
        })
        .catch( () => {});
    },
    submitPoc(pocForm){
      this.$refs[pocForm].validate(async (valid) => {
        if (valid) {
          const data = this.poc
          let result = await postPoc(data);
          if (result.data == 1) {
            this.pocFormVisible = false;
            this.$message({message: '提交成功！',type: 'success'})
            this.poc = {
              // 用户名称
              customerName:'',
              // 联系人
              contacts:'',
              // 电话
              tel:'',
              // 邮箱
              email:'',
              // 用户数量
              userQty:1,
              // 项目概况
              projectContent:'',
              // 计算层资源
              computerResources:'',
              // 计算层资源(其他)
              otherComputerResources:'',
              // 访问方式
              accessModeList:[],
              // 应用场景
              appScenarioList:[],
              // 其他应用场景
              otherAppScenario:'',
              // 桌面类型
              desktopTypeList:[],
              // 其他桌面类型
              otherDesktopType:'',
              // 终端类型
              terminalTypeList:[],
              // 其他
              other:''
            }
          }else if(result.data == 2){
            this.$message({message: '此手机号已经提交过poc申请，请勿重新提交',type: 'error'})
          }else{
            this.$message.error('提交失败!')
          }
        } else {
          this.$message.error('请正确填写内容，*为必填项')
          return false;
        }
      });
    },
  }
}
</script>

<style>
  .poc .el-select{
    width: 100%;
  }
  .poc .el-form-item__label{
    font-size:16px;
  }
  .poc .el-input__inner{
    border-radius: 20px;
  }
  .poc .el-textarea__inner{
    border-radius: 20px !important;
  }
</style>