<template>
  <div>
    <!--    底部页脚-->
<!--    <el-row>页脚</el-row>-->
    <el-row style="padding: 10vw 4vw 4vw;background: #eee;">
      <el-row style="display: flex;align-items: center;">
        <el-col :span="16" class="addr">
          <img src="@/assets/layout/footer.png" style="width: 30vw;">
          <p style="font-size: 3vw;">南京市六合区竹丰路5号1幢</p>
          <p style="font-size: 3vw;">400-097-6787</p>
        </el-col>
        <el-col :span="8" style="text-align: center">
          <img src="@/assets/layout/wechat.png" style="width: 20vw;margin-top: -20px;">
          <p style="font-size: 3vw;">扫码关注微信</p>
        </el-col>
      </el-row>
      <el-row>
        <img src="@/assets/layout/mapMo.png">
        <p style="text-align: center;font-size: 3vw;color: #333;">Copyright  2022 磐石（南京）云计算有限公司 <br/><a href="https://beian.miit.gov.cn/" style="color: #333;">苏ICP备2022030143号</a></p>
      </el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  name:'footerMobile'
}
</script>

<style scoped>
img{
  width: 100%;
}
  .addr p{
    font-size: 2vw;
  }
</style>