<template>
  <div>
    <div v-if="ismOrpc=='PCoperation'">
      
      <!--    中间部分-->
      <index></index>
       
    </div>
    <div v-else>
      <Mobile></Mobile>
    </div>

  </div>
</template>

<script>
import '../../assets/icon/iconfont.css'
import index from '@/views/index.vue'
import Mobile from '@/views/Moperation/Mobile.vue'
export default {
  name: "page",
  components:{index,Mobile},
  data() {
    return {
      ismOrpc:'PCoperation',//默认展示pc
      /* navLiDescBoxShow:false,
      navLiDxBoxShow:false,
      navLiJsBoxShow:false, */
      navShow:0,
      navList: ["磐石产品", "典型应用","培训认证","技术支持","在线体验", "信创生态"],
      // navList: ["磐石产品", "典型应用","信创生态","在线体验","培训认证", "合作伙伴"],
      
    }
  },
  created(){
    
    
  },
  methods:{
    
    open() {
      this.$message('该栏目正在维护中...');
    },
    
    
  }
}
</script>

<style scoped>
.el-header, .el-footer, .el-main {
  padding: 0;
}
a{
  text-decoration: none;
}
li{
  list-style: none;
}

</style>