// 对axios进行二次封装
import axios from 'axios';
// 引入进度条
import nprogress from 'nprogress';
// 引入进度条样式
import "nprogress/nprogress.css";
// start进度条开始  done进度条结束

//1.利用axios对象的方法create，去创建一个axios实例
//2.request就是axios。只不过稍微配置一下
const requests = axios.create({
    //配置对象
    //基础路径，发送请求的时候，路径中会出现api
    // baseURL: process.env.NODE_ENV === "production" ?'https://api.asilu.com':'',
    // baseURL: process.env.NODE_ENV === "production" ?'https://api.asilu.com':'',
    baseURL:'/api',
    // 代表请求超时的时间，15s
    timeout:15000,
})
// 请求拦截器，发送请求之前，请求拦截器可以检测出到，可以在请求发出去之前做一些事情
requests.interceptors.request.use((config) => {
  // config.headers['X-Requested-With'] = 'XMLHttpRequest'
    // 进度条开始动
    nprogress.start();

    //给请求头添加一个字段(userTempId)：和后台商量
    /* if(store.state.detail.uuid_token){
      config.headers.userTempId = store.state.detail.uuid_token;
    } */

    // 携带token给服务器
    /* if(store.state.user.token){
      config.headers.token = store.state.user.token;
    } */

    // config：配置对象，对象里有一个属性很重要，header请求头
    return config;
    
})
// 相应拦截器
requests.interceptors.response.use((res) => {
    // 进度条结束
    nprogress.done()
    // 成功的回调函数，服务器响应数据回来之后，相应拦截器可以检测到，可以做一些事情
    return res.data;
    
},(error) => {
    // 相应失败的回调函数
    return Promise.reject(new Error('faile'),error)
});

// 对外暴露
export default requests;