<template>
  <div>
    <TopNavVue/>

<!--    顶部轮播-->
    <el-row class="topRotate">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in rotateList" :key="index" data-swiper-autoplay="5000">
          <img :src="window.ipConfig.baseUrl + item.image"></div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </el-row>

    <!-- 磐石产品 -->
    <el-row class="currencyTitle">
      <p>磐石产品</p>
      <!-- <h6 @click="$router.push('/cloudDesktopMobile')">更多 &gt;</h6> -->
      <!-- <h6 @click="$bus.$emit('openDrawer')">更多 &gt;</h6> -->
      <div class="clearfix"></div>
    </el-row>
    <el-row>
      <el-col :span="24" class="mobpro">
        <!-- <img src="@/assets/mobile/product.png" /> -->
        <img src="@/assets/mobile/product1.png" />
        <!-- <p>磐石云桌面通过统一管理平台（控制引擎）将虚拟的桌面组件（包括应用软件、操作系统和用户数据等）部署在云数据中心，进行统一管理、集中控制和按需发布。支持安全地向PC、平板设备、笔记本电脑和云终端交付云桌面或应用，而且可以提供高清、流畅的用户体验，实现媲美本地PC及图形工作站的体验效果，在改变传统分布式桌面及设计应用单站点运行的现有模式下，既可以单独发布应用，又可以发布整个桌面系统，支持多种接入平台，有效降低运维成本，提高业务安全和数据安全，重新定义云管模型，重塑工作方式，为用户打造更加灵活的应用交付模式和办公空间。</p> -->
        <p>历经数次产品迭代，磐石已完成以云桌面产品解决方案为主，包含云终端、终端管理系统、一体机、安全网关、高价值应用上云以及Hypervisor等功能组件在内的完整产品布局。</p>
        <template v-if="showP">
          <p>其中云终端产品全面覆盖x86、ARM等主流芯片技术，累计推出八个型号十六款标准化产品，并完成与国产主流操作系统的全面适配；一体机产品通过构建轻量级基础计算服务平台，面向工作组级用户提供迅捷交付服务，用户开箱即用；安全网关产品则面向互联网用户及跨域集团客户提供可靠的安全接入服务，极大延伸了云桌面解决方案应用场景，使得“随时桌面”成为可能。</p>
          <p>磐石更加重视行业用户需求并关注行业细分市场，  面向高端设计类应用用户推出了“应用上云”云服务解决方案，合理优化用户设计类应用资源，打造高价值应用资源池，系统支持对资源有效利用情况实时跟踪并及时分析，支持资源主动回收，有效保护用户投资。</p>
          <p>磐石以其完整的产品形态、卓越的产品性能、全行业细分市场解决方案的全面响应以及面向高价值设计应用市场应用服务的全力打造，为良好市场预期奠定了坚实基础。</p>
          <div style="background: #ececec; padding: 10px 0;margin: 10px 0;">
            <h6>磐石云桌面</h6>
            <img src="@/assets/mobile/pp1.png" />
            <p>磐石云桌面通过统一管理平台（控制引擎）将虚拟的桌面组件（包括应用软件、操作系统和用户数据等）部署在云数据中心，进行统一管理、集中控制和按需发布。支持安全地向PC、平板设备、笔记本电脑和云终端交付云桌面或应用，而且可以提供高清、流畅的用户体验，实现媲美本地PC及图形工作站的体验效果，在改变传统分布式桌面及设计应用单站点运行的现有模式下，既可以单独发布应用，又可以发布整个桌面系统，支持多种接入平台，有效降低运维成本，提高业务安全和数据安全，重新定义云管模型，重塑工作方式，为用户打造更加灵活的应用交付模式和办公空间。</p>
          </div>
            <h6>磐石云终端</h6>
            <img src="@/assets/mobile/pp2.png" />
            <p>磐石云终端产品面向行业应用精心打造，外观设计精巧细致、内部结构严谨精密，广泛应用于各种场景。磐石云终端基于ARM、X86架构设计，支持信创芯片，符合信创产品技术路线。磐石云终端多采用固化集成架构，结构科学、运行稳定，支持长时间不间断运行，支持各种复杂应用环境。</p>
            <p>磐石云终端预置了重新定义封装的操作系统和丰富的功能套件，运行可靠、性能稳定，提供关键配置工具和管理工具，运行状态支持可视化管理，与磐石云桌面紧密融合、无缝对接。</p>
          <div style="background: #ececec; padding: 10px 0;margin: 10px 0;">
            <h6>磐石终端管理系统</h6>
            <img src="@/assets/mobile/pp3.png" />
            <p>持续运行是云应用服务的主要优势之一，磐石云桌面解决方案从底层计算资源规划、应用发布和虚拟桌面运行健诊等进行了全面优化和加固，配合磐石终端管理系统，实现对云桌面运行工况全面监控，更加提高了其稳定性。</p>
            <p>磐石云终端管理系统是一款为企业用户提供一站式云终端管理的服务平台，系统有三个子系统构成，分别为云终端管理平台、云终端客户端以及云终端数据采集器。</p>
            <p>磐石云终端管理系统旨在为终端企业用户提供有好的人机交互界面，实现态势分析、终端管理及监控告警、电源管理、远程协助、报表分析、桌面切换、文件摆渡以及软件分发功能，通过实时采集云终端设备运行数据，构建专属数学模型进行决策分析以提高运维效率降低运维成本。</p>
          </div>

            <h6>磐石应用上云</h6>
            <img src="@/assets/mobile/pp4.png" />
            <p>应用上云平台实现了交付虚拟应用的统一管理，通过识别应用类型，分类展示不同应用，全面提升系统的可扩展性与集成性，整合分散的各种信息化资源，实现业务协同，提高整体信息化水平，同时整合数据存取、数据管理、数据分析和数据展现等功能，结合数学建模技术，实现了应用使用过程中的频次、使用时长、许可授权数等多种数据的统计，为管理者提供了有效的决策辅助手段。</p>
          <div style="background: #ececec; padding: 10px 0;margin: 10px 0;">
            <h6>磐石安全网关</h6>
            <img src="@/assets/mobile/pp5.png" />
            <p>安全网关提供了强大的应用层安全管理策略和行为控制能力，通过集中策略管理提高应用安全性，保障应用和数据访问的安全。同时基于现有网络架构，通过配置负载均衡策略，增加吞吐量，加强网络数据处理能力，提高网络的灵活性和可用性。</p>
          </div>
          <h6>磐石云桌面一体机</h6>
          <img src="@/assets/mobile/pp6.png" />
          <p>面向工作组级安全桌面用户，运用超融合技术将计算资源、存储资源和网络资源实现一体化管理，采用模块化设计，支持弹性发布，开箱即用。</p>
        </template>
        <p style="text-align: center;margin-top: 10px;padding-top: 3px; width: 100%; margin-bottom: 15px;" :class="{bacc:showP}" @click="showP = !showP" id="dynamic">
          <svg v-show="!showP" t="1657179876038" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2333" width="16" height="16"><path d="M512 777.536l348.416-319.36L960 549.504 512 960 64 549.44 163.584 458.24 512 777.536z m0-394.24L860.416 64 960 155.264 512 565.76 64 155.264 163.584 64 512 383.36z" fill="#8a8a8a" p-id="2334"></path></svg>
          <svg v-show="showP" t="1657179918394" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4247" width="16" height="16"><path d="M512 246.464l-348.416 319.36L64 474.496 512 64l448 410.56-99.584 91.2L512 246.464z m0 394.24L163.584 960 64 868.736 512 458.24l448 410.496L860.416 960 512 640.64z" fill="#bfbfbf" p-id="4248"></path></svg>
        </p>
      </el-col>
    </el-row>
    <!-- <el-row class="currencyTitle" id="dynamic">
      <p>磐石产品</p>
      <h6 @click="$router.push('/cloudTerminalMobile')">更多 &gt;</h6>
      <div class="clearfix"></div>
    </el-row>
    <el-row>
      <el-col :span="22" :offset="1" class="navListBox">
        <div class="terminalHeader">
          <div 
            v-for="(item,index) in navList" 
            :key="index" class="terminalnav" 
            :class="{active:action == index}"
            @click="changeActiveTerminal(index)"
            >
            <img :src="item.imgSrc"/>
            <p>{{item.title}}</p>
          </div>
        </div>
      </el-col>
      <el-col :span="22" :offset="1">
        <div class="terminalBodya">
          <p>{{navList[action].title}}</p>
          <h6>{{navList[action].pro_tit}}</h6>
          <h5>{{navList[action].pro_det}}</h5>
        </div>
      </el-col>
    </el-row> -->
<!--    磐石动态-->
    <el-row class="currencyTitle">
      <p>磐石动态</p>
      <h6 @click="$router.push('/DynamicMobile')">更多 &gt;</h6>
      <div class="clearfix"></div>
    </el-row>
    <el-row class="pahshdt">
      <div class="swiper-container" id="panshdtswiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in panshDynamicList" :key="index" data-swiper-autoplay="7000" >
            <div class="panshdtdet" @click="handleDynamic(index)">
              <div class="dtdetimg">
                <img :src="window.ipConfig.baseUrl + item.image" />
              </div>
              <div class="dtdetp">
                <h6>{{item.title}}</h6>
                <p>{{item.content}}</p>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      <div id="case" style="height: 40px;"></div>

    </el-row>


    <el-dialog
      :title="panshDynamic.title"
      :visible.sync="dynamicDialogVisible"
      width="90%"
      class="panshDynamicD">
      <div v-html="panshDynamic.imageAndContent" class="DynamicDetail">
      </div>
    </el-dialog>

<!--    典型案例-->
    <div class="case">

      <el-row class="currencyTitle">
        <p>典型案例</p>
        <div class="pagebtn">
          <div id="button_prev">
            <img src="@/assets/layout/l.png" />
          </div>
          <div id="button_next">
            <img src="@/assets/layout/r.png" />
          </div>
        </div>
        <div class="clearfix"></div>
      </el-row>
      <el-row class="caseRotate">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item,index) in caseList" :key="'caseMo'+index" data-swiper-autoplay="12000">
              <div class="caseBox">
                <div class="caseboxa">
                  <img :src="window.ipConfig.baseUrl + item.image">
                  <p>{{item.type}}</p>
                </div>
                <div class="caseTitle">{{item.company}}</div>
                <div  class="caseDesc">{{item.content}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div style="padding-bottom:40px;" id="cooper"></div>
      </el-row>
    </div>
    <!--合作伙伴-->
    <div class="currencyTitle">
      <p>合作伙伴</p>
      <div class="clearfix"></div>
    </div>
    <el-row>
      <el-col :span="22" :offset="1">
        <div class="cooperaBox">
          <div v-for="(item,index) in cooperationRotateList" :key="'hezuo'+index" class="coopera">
            <!-- <img :src="window.ipConfig.baseUrl + item.image" @click="cooperaClick(index)"> -->
            <img :src="window.ipConfig.baseUrl + item.image">
          </div>
          <div class="clearfix"></div>
        </div>
      </el-col>
    </el-row>

    <el-row id="lx">
      <el-col :span="22" :offset="1" class="lxbtn">
        <el-button type="primary" round @click="lxDialog = true" >希望我们与您取得联系</el-button>
      </el-col>
    </el-row>
    

    <el-drawer
      title="希望进一步交流"
      :visible.sync="lxDialog"
      direction="btt"
      size="45%"
    >
      <div class="lxform">
        <div class="lxforma">
          <p>称呼<span>*</span></p>
          <input type="text" v-model="lxForm.userName" />
          <div class="clearfix"></div>
        </div>
        <!-- <div class="lxforma">
          <p>邮箱<span>*</span></p>
          <input type="text" v-model="lxForm.email" />
          <div class="clearfix"></div>
        </div> -->
        <div class="lxforma">
          <p>单位<span>*</span></p>
          <input type="text" v-model="lxForm.company" />
          <div class="clearfix"></div>
        </div>
        <div class="lxforma">
          <p>手机<span>*</span></p>
          <input type="text" v-model.number="lxForm.tel" />
          <div class="clearfix"></div>
        </div>
        <div class="lxforma">
          <p>联系内容<span>*</span></p>
          <textarea v-model="lxForm.content" />
          <div class="clearfix"></div>
        </div>
        <button class="submit" @click="submitForm">提交</button>
      </div>
    </el-drawer>

    <!-- 在线联系 -->
    <!-- <el-row class="lx" id="lx">
      <p class="lxtit">在线联系</p>
      <p class="lxdet">欢迎联系磐石云计算，请务必留下正确的联系方式，</p>
      <p class="lxdet">我们将尽快给您致电联系</p>
      <div class="lxform">
        <div class="lxforma">
          <p>姓名</p>
          <input type="text" v-model="lxForm.userName" />
          <div class="clearfix"></div>
        </div>
        <div class="lxforma">
          <p>邮箱</p>
          <input type="text" v-model="lxForm.email" />
          <div class="clearfix"></div>
        </div>
        <div class="lxforma">
          <p>公司</p>
          <input type="text" v-model="lxForm.company" />
          <div class="clearfix"></div>
        </div>
        <div class="lxforma">
          <p>手机</p>
          <input type="text" v-model.number="lxForm.tel" />
          <div class="clearfix"></div>
        </div>
        <div class="lxforma">
          <p>文字内容</p>
          <textarea v-model="lxForm.content" />
          <div class="clearfix"></div>
        </div>
        <button class="submit" @click="submitForm">提交</button>
      </div>
    </el-row> -->

    <footerMobileVue/>


<!--    合作伙伴  弹窗-->
    <el-dialog 
      :visible.sync="cooperDialog"
      width="90%"
    >
      <img :src="window.ipConfig.baseUrl + cooperDialogForm.img">
      <p class="cooperaDesc">{{cooperDialogForm.desc}}</p>
    </el-dialog>

<!--    返回顶部按钮-->
    <div id="goTop" @click="topreturn()">
      <i class="iconfont icon-shangyi"></i>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import {psDynamic,postContext,psCaseList,getPCBanners,getCooperations} from '@/api'
import TopNavVue from '../../components/topNav.vue';
import footerMobileVue from '../../components/footerMobile.vue';
// import Index from '../index.vue';
import publicFunction from '@/tools/publicFunction'
export default {
  name: "Mobile",
  components:{TopNavVue,footerMobileVue, },
  data(){
    return{
      // rotateList: [require('@/assets/index/rotateMo01.png'), require('@/assets/index/rotateMo02.png'), require('@/assets/index/rotateMo03.png'), require('@/assets/index/rotateMo04.png'), require('@/assets/index/rotateMo05.png')],
      rotateList:[],
      panshDynamicList: [],
      panshDynamic:{},
      caseList1:[
        {imgSrc:require('@/assets/index/case/1.png'),imgTit:'公共管理',title:'某不动产登记中心',coverDesc:'国土资源部直属事业单位，不动产登记中心窗口采用一机多用的办公模式，容易导致办公混乱及数据泄露。磐石云桌面分配用户独立工作桌面（防下载，加水印，数据集中存储，桌面统一运维等），提高用户办公效率，保障数据安全。'},
        {imgSrc:require('@/assets/index/case/2.png'),imgTit:'IT服务',title:'神州数码',coverDesc:'作为一家大型云计算及数字化服务商，神州数码在业务办公、数据安全、管理运维上有着极高的要求。磐石云桌面解决方案为其有效解决了存储资源利用率低、数据安全及运维难度大的难题，极大简化了云环境下的数据中心管理和运维工作，搭建了“高效率、易维护、更安全”的IT运营环境。'},
        {imgSrc:require('@/assets/index/case/4.png'),imgTit:'教育培训',title:'胶州市昱华实验小学',coverDesc:'教育资源数据分散存储，并且存在运维、管理难度大等难题，针对该学校的需求，通过磐石云桌面实现教务上云，对全校大规模教学终端进行集中统一管控，提高了应用管理能力和资源使用率，提升智慧教育的整体发展水平，满足了校园复杂多样的教学和管理需求。'},
        {imgSrc:require('@/assets/index/case/7.png'),imgTit:'智能制造',title:'联立(徐州)半导体办公桌面',coverDesc:'联立(徐州)半导体有限公司需满足多类型外设使用，并存在运维难度大等问题，携手磐石云桌面实现了办公桌面标准化，满足多类型外设使用需求，同时实现终端集中管理、统一状态监控，有效简化运维流程，实现内部或第三方运维人员的全天候远程运维。'},
        {imgSrc:require('@/assets/index/case/8.png'),imgTit:'互联网',title:'浙江天猫技术有限公司',coverDesc:'随着应用和规模越来越多元化，浙江天猫技术有限公司需要更高效、稳定、灵活的云服务以应对企业用户日益复杂多样的业务变化。磐石云桌面为生产研发等业务部门提供强有力的支撑，有效解决了其运维难度大、存储资源利用率低、总体成本高的难题，极大简化了云环境下的数据中心管理和运维工作。'},
        {imgSrc:require('@/assets/index/case/10.png'),imgTit:'军事工业',title:'某军工单位',coverDesc:'军事办公对桌面安全性要求高，某军工单位携手磐石云桌面更好的构建行政办公系统、搭建供各军工单位间进行信息共享和业务应用的政务平台，保证系统和数据安全可靠，从而实现整个组织政务系统的统一管理、资源共享、业务交互，未来军工行业也将主要采用自主创新的云桌面系统，构建可靠的安全信息环境。'},
        {imgSrc:require('@/assets/index/case/11.png'),imgTit:'医疗卫生',title:'上海某医院',coverDesc:'目前医院业务繁多，无法实现移动医疗，应用更新时间长且运维复杂，医疗数据存在安全隐患。通过磐石云桌面实现了全院医护人员随时随地登录医院业务系统以及所有设备统一管理和维护，保证数据集中化管理，根据医院要求，实现网络安全策略灵活配置，保证了医护人员在不同地点应用不同的安全策略。'},
        {imgSrc:require('@/assets/index/case/12.png'),imgTit:'智能制造',title:'某液晶平板制造商',coverDesc:'制造业生产环境复杂，物理设备数量多、分布广，需要集约化的管理工具，且文件一旦泄露难以追溯。通过磐石云桌面实现企业一键式管理及无纸化生产线，有效避免了图纸外泄，重要数据得到安全、可控、可追溯的管理。'},
      ],
      cooperationRotateList: [],
      action:0,
      navList:[
          {
            imgSrc:require('@/assets/panshProduct/production/2711.png'),
            title:'PS 2711+',
            pro_title:'PS 2711+',
            pro_tit:'精益所致  磐石为开',
            pro_det:'精益所致、磐石为开，磐石系列云终端解决方案支持构建行业特色应用场景，采用全面固化基础架构设计，配置独立电源管理模块，提供丰富弹性扩展空间，部署深度优化操作系统，支持终端管理，支持可靠持久运行。磐石云终端，致力行业产能提升！',
            pro_Appearance:require('@/assets/panshProduct/production/2711.png'),
            Appearance_interface:require('@/assets/panshProduct/production/2711_1.png'),
            configure:require('@/assets/panshProduct/production/2711_2.png')
          },
          {
            imgSrc:require('@/assets/panshProduct/production/8350.png'),
            title:'PS 8350',
            pro_title:'PS 8350',
            pro_tit:'还淳返朴  磐石之安',
            pro_det:'还淳返朴，磐石之安，磐石系列云终端产品以其经典造型、多能配置、丰富扩展、全面固化、可靠保障等功能特性支持适用各应用场景，既可部署于工作组级应用，又能满足高覆盖企业级应用。',
            pro_Appearance:require('@/assets/panshProduct/production/8350.png'),
            Appearance_interface:require('@/assets/panshProduct/production/8350_1.png'),
            configure:require('@/assets/panshProduct/production/8350_2.png')
          },
          {
            imgSrc:require('@/assets/panshProduct/production/1900.png'),
            title:'PS 1900/1900+',
            pro_title:'PS 1900/1900+',
            pro_tit:'沉稳凝邃 安如磐石',
            pro_det:'沉稳凝邃 安如磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            pro_Appearance:require('@/assets/panshProduct/production/1900.png'),
            Appearance_interface:require('@/assets/panshProduct/production/1900_1.png'),
            configure:require('@/assets/panshProduct/production/1900_2.png')
          },
          {
            imgSrc:require('@/assets/panshProduct/production/4405.png'),
            title:'PS 4405/4405+',
            pro_title:'PS 4405/4405+',
            pro_tit:'精致雕琢  坚如磐石',
            pro_det:'精致雕琢，坚如磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            pro_Appearance:require('@/assets/panshProduct/production/4405.png'),
            Appearance_interface:require('@/assets/panshProduct/production/4405_1.png'),
            configure:require('@/assets/panshProduct/production/4405_2.png')
          },
          {
            imgSrc:require('@/assets/panshProduct/production/7500.png'),
            title:'PS 7500',
            pro_title:'PS 7500',
            pro_tit:'幻彩逸生  泰山磐石',
            pro_det:'幻彩逸生  泰山磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            pro_Appearance:require('@/assets/panshProduct/production/7500.png'),
            Appearance_interface:require('@/assets/panshProduct/production/7500_1.png'),
            configure:require('@/assets/panshProduct/production/7500_2.png')
          },
          {
            imgSrc:require('@/assets/panshProduct/production/4405.png'),
            title:'PS Z6540',
            pro_title:'PS Z6540',
            pro_tit:'精致雕琢  坚如磐石',
            pro_det:'精致雕琢，坚如磐石，磐石系列云终端产品为专属应用场景量身打磨。圆润纤小机身，钢琴烤漆工艺，配合多彩LED氛围灯光装饰，大方典雅，更显生机；全面优化空间管理，储备丰富扩展空间，为行业应用无限储能！',
            pro_Appearance:require('@/assets/panshProduct/production/4405.png'),
            Appearance_interface:require('@/assets/panshProduct/production/4405_1.png'),
            configure:require('@/assets/panshProduct/production/6540_3.png')
            // configure:require('@/assets/panshProduct/cloudTerminal/peizhiyilan.png')
          },
      ],
      cooperDialog:false,
      cooperDialogForm:[{title: ''},{img:''},{desc:''}],
      lxForm:{
        userName:'',
        // email:'',
        company:'',
        tel:'',
        content:'',
      },
      showP:false,
      dynamicDialogVisible:false,
      caseList:[],
      lxDialog:false,
    }
  },
  created() {
    this.getPsDynamic()
    this.getCaseList()
    this.getMobBanner()
    this.getCooperations()
  },
  mounted() {
    publicFunction.$on('showLx',() => {this.lxDialog = true})
    publicFunction.$on('demo',(path) => {
      this.jumplocation(path)
    })
    this.jumplocation()
    this.mySwiperCase()
    this.mySwiperDynamic()
    
    
    // window.addEventListener('scroll', this.handleScroll)

    /* var hash = window.location.hash;
    var index = hash.lastIndexOf("#");
    if (index != -1) {
       var id = hash.substring(index + 1, hash.length + 1);
       var div = document.getElementById(id);
       if (div) {
         setTimeout(function () {
           console.log($(div).offset().top);
           $('html, body').animate({scrollTop: $(div).offset().top - 43}, 500)
         }, 500);
       }
    } */

  },
  computed:{
    window: function () {
      // `this` 指向 vm 实例
      return window
    }
  },
  methods: {
    // 判断是否存在锚点并进行跳转
    jumplocation(){

      let hash = window.location.hash;
      let index = hash.lastIndexOf("#");
      if (index != -1) {
        let id = hash.substring(index + 1, hash.length + 1);
        var div = document.getElementById(id);
        if (div) {
          
          setTimeout(function () {
            // console.log($(div).offset().top);
            // $('html, body').animate({scrollTop: $(div).offset().top - 63}, 500)
            if(id == 'dynamic'){
              $('html, body').animate({scrollTop: $(div).offset().top}, 500)
            }else{
              $('html, body').animate({scrollTop: $(div).offset().top - 70}, 500)
            }
            // console.log(id)
            
            if(id == 'lx'){
              publicFunction.$emit('showLx')
            }
          }, 500);
        }
      }

    },
    async getMobBanner(){
      const result = await getPCBanners(1)
      this.rotateList = result.data
    },
    async getCooperations(){
      const result = await getCooperations()
      this.cooperationRotateList = result.data
      console.log(result)
      // this.rotateList = result.data
    },
    // swiper
    mySwiperCase(){
      setTimeout(() => {
        new Swiper('.caseRotate .swiper-container', {
          loop: true,
          // 如果需要分页器
          // pagination: '.swiper-pagination',
          //如果需要自动切换轮播图
          autoplay: {
            delay: 1000,//时间 毫秒
            disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
          },
          // 按钮
          prevButton:'#button_prev',
          nextButton:'#button_next',
          autoplayDisableOnInteraction : false
        });
        new Swiper('.topRotate .swiper-container', {
          loop: true,
          // 如果需要分页器
          pagination: '.swiper-pagination',
          //如果需要自动切换轮播图
          autoplay: {
            delay: 10000,//时间 毫秒
            disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
          },
          autoplayDisableOnInteraction : false,
        });
      }, 1000);
      
    },
    mySwiperDynamic(){
      setTimeout(() => {
        new Swiper('#panshdtswiper', {
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        slidesPerView : 2,
        loop: false,
        // 如果需要分页器
        // pagination: '.swiper-pagination',
        //如果需要自动切换轮播图
        autoplay: {
          delay: 6000,//时间 毫秒
        },
        autoplayDisableOnInteraction : false
      });
      },1000)
    },
    // 获得磐石动态信息
    async getPsDynamic(){
      const result = await psDynamic();
      for (let index = 0; index < 4; index++) {
        this.panshDynamicList.push(result.data[index])
        
      }
      // this.panshDynamicList = result.data
    },
    
    handleScroll () {
      var topHeight = document.documentElement.scrollTop || document.body.scrollTop
      if (topHeight>=650){
        document.getElementById("goTop").style.visibility="visible";
      }else if (topHeight<650){
        document.getElementById("goTop").style.visibility="hidden";
      }
    },
    topreturn:function(){
      document.documentElement.scrollTop = document.body.scrollTop =0;
      document.getElementById("goTop").style.visibility="hidden";
    },
    cooperaClick(index){
      this.cooperDialog = true
      // this.cooperDialogForm.title = this.cooperationRotateList[index].title
      this.cooperDialogForm.img = this.cooperationRotateList[index].image
      this.cooperDialogForm.desc = this.cooperationRotateList[index].content
    },
    // 提交--在线联系
    async submitForm(){
      if(this.lxForm.userName == ''){
        this.$message.info('称呼不能为空')
        return false
      }else if(this.lxForm.company == ''){
        this.$message.info('单位不能为空')
        return false
      }else if(this.lxForm.tel == ''){
        this.$message.info('手机不能为空')
        return false
      }else if((this.lxForm.tel + '').length != 11){
        this.$message.info('请输入正确的手机号')
        return false
      }
      const result = await postContext(this.lxForm)
      if(result.code == 0){
        this.$message.success('提交成功！')
        this.lxForm = {
          userName:'',
          company:'',
          tel:'',
          content:'',
        }
        this.lxDialog = false
      }else{
        this.$message.danger('提交失败')
      }
    },
    // 
    handleDynamic(index){
      console.log(index)
      this.panshDynamic = this.panshDynamicList[index]
      this.dynamicDialogVisible = true
    },
    // 获取案例
    async getCaseList(){
      const result = await psCaseList();
      this.caseList = result.data
    },
    changeActiveTerminal(index){
      this.action = index
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>
/*重置样式*/
li{
  list-style: none;
}
a{
  text-decoration: none;
}
*{
  font-size: 3vw;
}
img{
  width: 100%;
}
/*
  1、清除重置样式  字体大小
  2、磐石动态  标题和详情的   单行显示

*/
/*页面样式*/
.clearfix{clear: both;}
/*顶部logo*/
>>> .panshDynamicD .el-dialog__title{
  display: block;
  width: 95%;
}
.topLogo{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 3vw 4vw;
  box-shadow: 0px 0px 10px 2px #144f7d;
}
.topRotate{
  margin-top: 12.9%;
}
.icon-gengduo{
  font-size: 4vw;
  z-index: 2;
  background-color: #0c3056;
  padding: 1%;
}
/*顶部 右侧弹窗*/
.panshDrawer{
  padding: 0 4vw;
}
.panshDrawer li{
  margin: 6vw 0;
  font-size: 4.5vw;
}
>>> .el-drawer__header{
  font-size: 4.5vw;
  margin-bottom: 0;
  padding-bottom: 20px;
}
>>> .el-drawer__body{
}
/*顶部轮播图*/
.topRotate img{
 width: 100%;
}
/*标题通用*/
.currencyTitle{
  /* font-size: 5.65vw; */
  margin: 5vw 0 4vw;
  padding: 0 3vw;
}
.currencyTitle p{
  font-size: 4vw;
  float: left;
  border-left: 5px solid #E32121;
  padding-left: 10px;
}
.currencyTitle h6{
  float: right;
  font-size: 4vw;
  color: #666;
  font-weight: normal;
}
/* 产品 */
.mobpro img{
  width: 92%;
  display: block;
  margin: 0 auto 10px;
}
.mobpro h6{
  width: 92%;
  display: block;
  margin: 10px auto 10px;
  font-size: 3.7vw;
}
.mobpro p{
  width: 92%;
  display: block;
  margin: 0 auto;
  font-size: 3.6vw;
  color: #666;
  text-indent: 2em;
}
.mobpro p svg{
  display: block;
  margin: 0 auto;
}
.bacc{
background: #ececec;
}

/*磐石动态*/
.pahshdt{
  margin: 0 3vw;
}
.panshdtdet{
  padding: 0 1vw;
}
.dtdetimg{
  border-radius: 10px;
  overflow: hidden;
}
.dtdetimg img{
  width: 100%;
}
.dtdetp{
  margin-top: 4px;
}
.dtdetp h6{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: normal;
  font-size: 13px;
}
.dtdetp p{
  font-size: 12px;
  color: #666;
  margin-top: 2px;
  line-height: 16px;
  height: 32px;
  overflow: hidden;
}
/*典型案例*/
.caseRotate .swiper-container{
  padding-top: 30px;
}
.caseBox{
  margin: 0 4vw;
  background: #24789D;
  border-radius: 16px;
}
.caseboxa{
  position: relative;
  top: -24px;
  width: 90%;
  margin: 0 auto;
  border-radius: 16px;
  overflow: hidden;
}
.pagebtn{
  float: right;
  display: flex;
  justify-content: flex-end;
  padding: 0 3%;
}
.pagebtn div{
  width: 32px;
  margin: 0 5px;
}
.caseboxa p {
  position: absolute;
  background: rgba(95, 173, 234, 0.68);
  font-size: 4vw;
  color: #fff;
  padding: 2%;
  bottom: 3px;
  left: 0;
}
.caseTitle{
  font-size: 4.25vw;
  font-weight: bold;
  margin: 2vw 0;
  padding: 0;
  justify-content: normal;
  color: #fff;
  padding-left: 5%;
  position: relative;
  top: -10px;
}
.caseDesc{
  font-size: 3.7vw;
  text-indent: 2em;
  color: #fff;
  padding: 0 5%;
  position: relative;
  top: -10px;
  height: 133px;
}
/*合作伙伴*/
/* .cooperaBox{
  background: #144F7D;
  margin: 0 2vw;
  padding: 3% 1%;
  display: flex;
  flex-wrap: wrap;
}
.coopera{
  width: 50%;
  padding: 1vw 0vw;
}
.coopera img{
  width: 96%;
  margin: 0 auto;
  display: block;
}
>>> .el-dialog{
  width: 80%;
  margin-top: 40vw!important;
}
.cooperaDesc{
  font-size: 4.25vw;
  color: #666666;
  text-indent: 2em;
  line-height: 6.25vw;
} */
/* 在线联系 */
.lxbtn .el-button{
  width: 90%;
  margin:5vw auto 4vw;
  display: block;
}
.lx{
  background: #F4F4F4;
  padding-top: 20px;
  padding: 3vw;
}
.lxtit{
  text-align: center;
  font-size: 6.25vw;
  color: #000;
  font-weight: bold;
  margin-bottom: 5px;
}
.lxdet{
  text-align: center;
  font-size: 3.25vw;
  margin-top: 5px;
}
.lxform{
  margin-top: 10px;
  padding: 0 20px;
}
.lxforma{
  border-bottom: 1px solid #bbb;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.lxforma p{
  float: left;
  width: 20%;
  font-size: 3.6vw;
  line-height: 20px;
}
.lxforma p span{
  color: #E32121;
}
.lxforma input{
  float: left;
  display: block;
  width: 75%;
  border: none;
  line-height: 20px;
  background: #fff;
  outline: none;
}
.lxforma textarea{
  float: left;
  display: block;
  width: 75%;
  border: none;
  line-height: 20px;
  background: #fff;
  outline: none;
}
.submit{
  width: 140px;
  height: 40px;
  line-height: 40px;
  border: none;
  background: #2AA6E6;
  color: #fff;
  border-radius: 20px;
  margin: 0 auto;
  display: block;
}
/* 合作伙伴 */
.cooperaBox{background: #24789D;padding: 1vw;}
.coopera{width: 50%;float: left;}
.coopera img{width: 86%;margin: 2vw auto;display: block;}
.cooperaDesc{font-size: 3.8vw;color: #888;text-indent: 2em;}
/*  */
>>> .DynamicDetail p {
  font-size: 3.5vw;
    text-indent: 2em;
  color: #333;
}
>>> .DynamicDetail img{
  max-width: 100%;
  margin: 0 auto;
  display: block;
  height: auto;
  margin: 10px 0;
}
/* 磐石产品 */
.terminalHeader{display: flex;width: 100%;overflow: auto;}
.terminalnav{border-bottom: 3px solid #ECECEC;}
.terminalnav img{height: 60px;width: auto;margin: 0 20px;}
.terminalnav p{font-size: 3.5vw;color: #333;text-align: center;}
.terminalBodya{padding-top: 20px;}
.terminalBodya p{font-size: 3.6vw;color: #333;font-weight: bold;}
.terminalBodya h6{font-size: 3.6vw;color: #333;margin: 2vw 0;}
.terminalBodya h5{font-size: 3.5vw;color: #666;font-weight: normal;}
.active{border-bottom: 3px solid #FF3535;}
/*返回顶部*/
#goTop{
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  font-size: 0.5rem;
  background:rgba(230, 230, 230, 0.7);
  position: fixed;
  z-index: 100;
  bottom: 6%;
  right: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  color:#a3aa97;
}
</style>