<template>
  <div>
    <!--    logo-->
    <el-row class="topLogo">
      <el-col :span="23"><img src="@/assets/layout/logo.png" style="width: 25vw" @click="$router.push('mob')" ></el-col>
      <el-col :span="1">
        <img src="@/assets/layout/more.png" @click="openRightDrawer"/>
        <!-- <i class="iconfont icon-gengduo" @click="openRightDrawer"></i> -->
      </el-col>
    </el-row>
    <!-- 右侧弹出层 -->
    <rightDrawer ref="son"></rightDrawer>
  </div>
</template>

<script>
import rightDrawer from "@/components/rightDrawer";

export default {
  name:'topNav',
  components:{ rightDrawer},
  methods:{
    openRightDrawer(){
      this.$refs.son.showRightDrawer()
    },
  }
}
</script>

<style scoped>
/*重置样式*/
li{
  list-style: none;
}
a{
  text-decoration: none;
}
*{
  font-size: 3vw;
}
img{
  width: 100%;
}
  .clearfix{clear: both;}
  .topLogo{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 3vw 4vw;
  box-shadow: 0px 0px 10px 2px #b1bec8;
}
.topRotate{
  margin-top: 12.9%;
}
.icon-gengduo{
  font-size: 4vw;
  z-index: 2;
  background-color: #0c3056;
  padding: 1%;
}
</style>