import Vue from 'vue'
import App from './App.vue'
import router from "./routers";
import $ from "jquery"

window.$=$

// 引入仓库
import store from '@/store';

import * as API from '@/api';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import downloadLocalFile from './routers/downloadFile'

Vue.prototype.$downloadLocalFile = downloadLocalFile

import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

// 引入全局组件
import NavBar from '@/components/layout/NavBar'
import Footer from '@/components/layout/Footer'
import Verify from '@/components/layout/Verify'

// 注册全局组件
Vue.component('NavBar',NavBar)
Vue.component('Footer',Footer)
Vue.component('Verify',Verify)

// 引入延迟加载
import VueLazyload from "vue-lazyload";
// 使用延迟加载
Vue.use(VueLazyload, {
  preLoad: 1,
  // loading: require('./assets/index/loading.png'),
  attempt: 2,
})


Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面meta */
  if(to.meta.content){
    let head = document.getElementsByTagName('head');
    let meta = document.createElement('meta');
    meta.content = to.meta.content;
    head[0].appendChild(meta)
  }
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next()
});

new Vue({
  router,
  el: '#app',
  render: h => h(App),
  store,
  beforeCreate() {
    Vue.prototype.$API = API;
    Vue.prototype.$bus = this
  },
}).$mount('#app')
