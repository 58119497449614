<template>
  <div>
    <el-drawer 
      title="pansh" 
      :visible.sync="panshDrawer" 
      size="100%" 
      :with-header="false"
      :show-close="false">
      <div class="logo">
        <img src="@/assets/layout/logo.png" style="width: 25vw" >
        <div @click="panshDrawer = false" style="padding-top: 3px;">
          <svg t="1655257813547" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4296" width="16" height="16"><path d="M925.468404 822.294069 622.19831 512.00614l303.311027-310.331931c34.682917-27.842115 38.299281-75.80243 8.121981-107.216907-30.135344-31.369452-82.733283-34.259268-117.408013-6.463202L512.000512 399.25724 207.776695 87.993077c-34.675754-27.796066-87.272669-24.90625-117.408013 6.463202-30.178323 31.414477-26.560936 79.375815 8.121981 107.216907l303.311027 310.331931L98.531596 822.294069c-34.724873 27.820626-38.341237 75.846432-8.117888 107.195418 30.135344 31.43699 82.72919 34.326806 117.408013 6.485715l304.178791-311.219137 304.177767 311.219137c34.678824 27.841092 87.271646 24.951275 117.408013-6.485715C963.808618 898.140501 960.146205 850.113671 925.468404 822.294069z" p-id="4297" fill="#E40F0F"></path></svg>
        </div>
      </div>
      <ul class="panshDrawer">
        <!-- <router-link v-for="(item,index) in drawerList" :key="index" :to="item.toGo">
          <li @click="closeDrawer">
            {{item.title}}
          </li>
        </router-link> -->
        <li @click="routerpush('/mob')">
          <p>首页</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li>
        <li @click="innerDrawer = true">
          <p :class="{active:innerDrawer}">磐石产品</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li>
        <!-- <li @click="routerpush('/moPanshProduct')">
          <p>磐石动态</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li> -->
        <!-- <li @click="routerpush('/mob#dynamic','dynamic')">
          <p>磐石动态</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li>
        <li @click="routerpush('/mob#case','case')">
          <p>典型案例</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li>
        <li @click="routerpush('/mob#cooper','cooper')">
          <p>合作伙伴</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li> -->
        <li @click="routerpush('/dxyyMobile')">
          <p>典型应用</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li>
        <li @click="routerpush('/EcologyMobile')">
          <p>信创融合</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li>
        <li @click="routerpush('/xcstMobile')">
          <p>信创生态</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li>
        <li @click="routerpush('/qdtxMobile')">
          <p>渠道体系</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li>
        <li @click="routerpush('/mob#lx','lx')">
          <p>联系我们</p>
          <svg t="1655274019533" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5195" width="16" height="16"><path d="M312.888889 995.555556c-17.066667 0-28.444444-5.688889-39.822222-17.066667-22.755556-22.755556-17.066667-56.888889 5.688889-79.644445l364.088888-329.955555c11.377778-11.377778 17.066667-22.755556 17.066667-34.133333 0-11.377778-5.688889-22.755556-17.066667-34.133334L273.066667 187.733333c-22.755556-22.755556-28.444444-56.888889-5.688889-79.644444 22.755556-22.755556 56.888889-28.444444 79.644444-5.688889l364.088889 312.888889c34.133333 28.444444 56.888889 73.955556 56.888889 119.466667s-17.066667 85.333333-51.2 119.466666l-364.088889 329.955556c-11.377778 5.688889-28.444444 11.377778-39.822222 11.377778z" p-id="5196"></path></svg>
        </li>
      </ul>
      <el-drawer
        size="70%"
        :append-to-body="true"
        :visible.sync="innerDrawer"
        :modal="false"
        :show-close="false"
        id="innerdrawer">
        <div class="production">

          <div class="proa">
            <p class="protit">磐石云桌面</p>
            <div class="prob">
              <div class="proc" @click="routerpush('cloudDesktopMobile')">
                <img src="@/assets/mobile/p1.png" />
                <p>云桌面</p>
              </div>
              <div class="proc" @click="routerpush('cloudApplicationMobile')">
                <img src="@/assets/mobile/p2.png" />
                <p>应用上云</p>
              </div>
            </div>
          </div>

          <div class="proa">
            <p class="protit">磐石云终端</p>
            <div class="prob">
              <div class="proc" @click="routerpushProduction(0)">
                <img src="@/assets/index/pro2.png" />
                <p>PS4405</p>
              </div>
              <div class="proc" @click="routerpushProduction(1)">
                <img src="@/assets/index/pro2.png" />
                <p>PS7500</p>
              </div>
              <div class="proc" @click="routerpushProduction(0)">
                <img src="@/assets/mobile/p7.png" />
              </div>
            </div>
          </div>

          <div class="proa">
            <p class="protit">磐石一体机</p>
            <div class="prob">
              <!-- <div class="proc" @click="$message.info('该产品正在筹备中')"> -->
              <div class="proc" @click="$router.push('psytjMobile')">
                <img src="@/assets/mobile/p8.png" />
                <p>磐石一体机</p>
              </div>
            </div>
          </div>

          <div class="proa">
            <p class="protit">安全网关</p>
            <div class="prob">
              <div class="proc" @click="$router.push('aqwgMobile')">
                <img src="@/assets/mobile/p5.png" />
                <p>安全网关</p>
              </div>
            </div>
          </div>

          <div class="proa">
            <p class="protit">视频运维安全管理平台</p>
            <div class="prob" @click="routerpush('OperationsMobile')">
              <div class="proc">
                <img src="@/assets/mobile/p6.png" />
                <p>视频运维安全管理平台</p>
              </div>
            </div>
          </div>

        </div>
      </el-drawer>
    </el-drawer>
  </div>
</template>

<script>
import publicFunction from '@/tools/publicFunction'
export default {
  name: "rightDrawer",
  data(){
    return{
      panshDrawer: false,
      innerDrawer: false,
      isActive:false
    }
  },
  created(){
    this.$bus.$on('openDrawer',this.open)
  },
  methods:{
    open(){

      this.panshDrawer = true
      setTimeout(() => {
        this.innerDrawer = true
        
      }, 100);
    },
    showRightDrawer(){
      this.panshDrawer = true
    },
    routerpush(path,id){

      

      if(this.$route.path == '/mob'){
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = '#' + id
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        if(path == "/mob#lx"){
          publicFunction.$emit('showLx')
        }
      }else{
        publicFunction.$emit('demo')
      }


      this.panshDrawer = false
      this.innerDrawer = false
      this.$router.push({path:path,},()=>{},()=>{})

    },
    routerpushProduction(active){
      if(this.$route.path == '/cloudTerminalMobile'){
        // console.log(active)
        this.panshDrawer = false
        this.innerDrawer = false
        this.$bus.$emit('changeAct',active)
      }else{
        this.$router.push({
          name: "cloudTerminalMobile",
          query: { active }
        });
      }
      
    }
  },
}
</script>

<style>
/* 抽屉logo */
.el-message .el-icon-info {
    color: #909399;
    font-size: 20px;
}
.logo{
  margin: 10px;
  display: flex;
  justify-content: space-between;
}
li{
  list-style: none;
}
a{
  text-decoration: none;
}
/*顶部 右侧弹窗*/
.panshDrawer{
  padding: 0 4vw;
}
.panshDrawer li{
  margin: 6vw 0;
  font-size: 4.3vw;
  display: flex;
  justify-content: space-between;
}
.active{
  color: red;
}
>>> .el-drawer__header{
  font-size: 4.5vw;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.production{
  padding-left: 4vw;
}
.protit{
  font-size: 4vw;
  margin-bottom: 2vw;
}
.proa{
  margin-bottom: 6vw;
}
.prob{
  display: flex;
}
.proc{
  display: flex;
  margin-right: 6vw;
}
.proc:last-child{
  margin-right: 0;
}
.proc img{
  width: 30px;
}
.proc p{
  font-size: 3vw;
  line-height: 30px;
  padding-left: 5px;
  color: #666;
}
#innerdrawer .el-drawer{
  box-shadow: none;
}
#innerdrawer .el-drawer__header{
  padding: 0;
  margin: 0;
}
#innerdrawer .el-drawer{
  margin-top: 14vw;
  border-left: 1px solid #c5c2c2;

}
</style>