<template>
  <div>
    <el-dialog
      title="我的POC"
      :visible.sync="dialogVisible"
      :width="DialogWidth"
      :before-close="handleClose"
      @open="opend">
      <div>
        <div v-show="!checkSuccess">
          <el-input v-model="tel" placeholder="请输入手机号"></el-input>
        </div>
        <div v-show="checkSuccess">
          <div>
            <el-form 
              label-position="left" 
              inline
              class="demo-table-expand"
              label-width="120px"
              >
              <el-form-item label="客户名称">
                <span>{{ PocDet.customerName }}</span>
              </el-form-item>
              <el-form-item label="联系人">
                <span>{{ PocDet.customerName }}</span>
              </el-form-item>
              <el-form-item label="手机号">
                <span>{{ PocDet.tel }}</span>
              </el-form-item>
              <el-form-item label="邮箱">
                <span>{{ PocDet.email }}</span>
              </el-form-item>
              <el-form-item label="用户数量">
                <span>{{ PocDet.userQty }}</span>
              </el-form-item>
              <el-form-item label="项目概况">
                <span>{{ PocDet.projectContent }}</span>
              </el-form-item>
              <el-form-item label="计算层资源">
                <span>{{ PocDet.computerResources }}</span>
              </el-form-item>
              <el-form-item label="其他计算层资源">
                <span>{{ PocDet.otherComputerResources }}</span>
              </el-form-item>
              <el-form-item label="应用场景">
                <span>{{ PocDet.appScenario }}</span>
              </el-form-item>
              <el-form-item label="其他应用场景">
                <span>{{ PocDet.otherComputerResources }}</span>
              </el-form-item>
              <el-form-item label="桌面类型">
                <span>{{ PocDet.desktopType }}</span>
              </el-form-item>
              <el-form-item label="其他桌面类型">
                <span>{{ PocDet.otherDesktopType }}</span>
              </el-form-item>
              <el-form-item label="访问方式">
                <span>{{ PocDet.accessMode }}</span>
              </el-form-item>
              <el-form-item label="终端类型">
                <span>{{ PocDet.terminalType }}</span>
              </el-form-item>
              <el-form-item label="其他">
                <span>{{ PocDet.other }}</span>
              </el-form-item>
            </el-form>
          </div>
          <div style="width: 80%;margin: 0 auto;margin-top: 20px;">
            <el-steps :active="PocDet.status" v-show="PocDet.status != 3" finish-status="success" process-status="success">
              <el-step title="已提交" :description="PocDet.status == 0 ? PocDet.updateTime : ''" icon="el-icon-folder-checked"></el-step>
              <el-step title="审核中" :description="PocDet.status == 1 ? PocDet.updateTime : ''" icon="el-icon-view"></el-step>
              <el-step title="审核通过" :description="PocDet.status == 2 ? PocDet.updateTime : ''" icon="el-icon-s-check"></el-step>
            </el-steps>
            <el-steps :active="PocDet.status" v-show="PocDet.status == 3" finish-status="error">
              <el-step title="已提交" :description="PocDet.status == 0 ? PocDet.updateTime : ''" icon="el-icon-folder-checked"></el-step>
              <el-step title="审核中" :description="PocDet.status == 1 ? PocDet.updateTime : ''" icon="el-icon-view"></el-step>
              <el-step title="申请驳回" :description="PocDet.status == 3 ? PocDet.updateTime : ''" icon="el-icon-s-release"></el-step>
            </el-steps>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-show="this.checkSuccess" type="primary" @click="Close">确 定</el-button>
        <el-button v-show="!this.checkSuccess" type="primary" @click="geMyPoc">确 定</el-button>
      </span>
    </el-dialog>
    <!-- <el-button @click=""></el-button> -->
  </div>
</template>

<script>
import {geMyPoc} from '@/api'
export default {
  name:'MyPoc',
  data(){
    return {
      dialogVisible:false,
      tel:'',
      PocDet:{
        status:0,
      },
      checkSuccess:false,
      DialogWidth:'30%',
      timer:{}
    }
  },
  mounted(){
    this.$bus.$on('showMyPoc',this.shouMyPoc)
  },
  methods:{
    shouMyPoc(){
      this.dialogVisible = true
    },
    emitpoc(){
      this.$bus.$emit('showMyPoc')
    },
    Close(){
      this.tel = ''
      this.dialogVisible = false
      this.timer = setTimeout(() => {
        this.DialogWidth = '30%'
        this.checkSuccess = false
        this.PocDet = {status:0}
      }, 5000);
    },
    handleClose() {
      this.tel = ''
          this.dialogVisible = false
          if(this.checkSuccess){
            this.timer = setTimeout(() => {
              this.DialogWidth = '30%'
              this.checkSuccess = false
              this.PocDet = {status:0}
            }, 300000);
          }
    },
    //打开dialog清空定时器
    opend(){
      clearTimeout(this.timer)
    },
    // 提交手机号
    async geMyPoc(){
      var regExp = new RegExp(/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/);
      if (!regExp.test(this.tel)) {
        this.$message.info('请输入正确的手机号码')
        return
      }
      if(this.checkSuccess){
        this.dialogVisible = false
      }else{
        const result = await geMyPoc(this.tel);
        if(result.data[0]){
          /* 查询成功 */
          this.PocDet = result.data[0]
          this.DialogWidth = '60%'
          this.checkSuccess = true
        }else{
          /* 查询失败 */
          this.$confirm('未查找到您的手机号，请选择操作', '', {
            confirmButtonText: '提交POC申请',
            cancelButtonText: '重新输入',
            type: 'warning'
          }).then(() => {
            this.$bus.$emit('showPoc')
            this.dialogVisible = false
            this.tel = ''
          }).catch(() => {
            // 重新输入
          });
        }

      }
    }
  }
}
</script>

<style>
.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 10px;
    width: 50%;
  }
.demo-table-expand .el-form-item__content{
  max-width: calc(100% - 150px);
  line-height: 24px;
  margin-top: 8px;
  color: #000;
}
.demo-table-expand .el-form-item__label{
  color:#888;
}
</style>