//引入vuex
import Vue from 'vue';
import Vuex from 'vuex';
import home from './home'

// 使用插件
Vue.use(Vuex);

//对外暴露store类的一个实例
export default new Vuex.Store({
  modules:{
      home,
  }
});
