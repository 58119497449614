<template>
  <!--    页脚部分-->
      <div class="footer">
        <div class="footerTop">
          <div class="contact">
            <img src="../../assets/layout/footer.png" alt="磐石，正在改变传统">
            <div class="contactDesc">
              <p>南京市六合区竹丰路5号1幢</p>
              <p>400-097-6787</p>
            </div>
          </div>
          <div class="footerMap">
            <img src="../../assets/layout/map.png" alt="交付中心分布">
          </div>
          <div class="WeChat">
            <img src="../../assets/layout/qrcode.jpg" style="width:160px" />
            <p>扫码关注公众号</p>
          </div>
        </div>

        <div class="footerBottom">Copyright © 2022  磐石（南京）云计算有限公司       <a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022030143号</a></div>
      </div>
</template>

<script>
export default {
  name:'Footer',
  data(){
    return{

    }
  },
  
}
</script>

<style scoped>
.el-header, .el-footer, .el-main {
  padding: 0;
}
a{
  text-decoration: none;
}
li{
  list-style: none;
}
  /*底部页脚部分*/
.footer{
  background-color: #f5f5f5;
}
.footerTop{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footerTop .contact{
  margin: 0 0 0 6%;
  flex: 1;
  width: 20%;
}
.footerTop .contact img{
  /* width: 221px;
  height: 88px; */
  width: 221px;
}
.footerTop .contactDesc{
  color: #101010;
  font-size: 14px;
  font-family: SourceHanSansSC-regular;
  margin-top: 8%;
  width: 100%;
}
.footerTop .contactDesc p{
  line-height: 26px;
}


.footerMap{
  display: flex;
  align-items: flex-end;
  width: 50%;
}
.footerMap img{
  width: 100%;
  max-width: 779px;
  margin: 0 auto;
  margin-top: 35px;
}
.footerTop .WeChat{
  text-align: center;
  line-height: 23px;
  color: #1C1D1C;
  margin-top: 4%;
  flex: 1;

}
.footerBottom{
  width: 100%;
  text-align: center;
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-family: SourceHanSansSC-regular;
  background-color: #D7D6D6;
  padding: 12px 0;
}
.footerBottom a{
  color: rgba(16, 16, 16, 100);
}
</style>