<template>
  <div class="img-canvas">
    <canvas
      id="canvas"
      :width="width"
      :height="height"
      @click="handleDraw"
    ></canvas>
  </div>
</template>
 
<script>
export default {
  name:'Verify',
  data() {
    return {
      allTexts: "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890", // 字符串
      width: 120,
      height: 40,
      imgCode: "",
    };
  },
  mounted() {
    // 初始化绘制图片验证码
    this.imgCode = this.draw();
  },
  methods: {
    //todo 点击图片刷新
    handleDraw() {
      this.imgCode = this.draw();
    },
    //todo 产生随机数
    randomNum(min, max) {
      return parseInt(Math.random() * (max - min) + min);
    },
    //todo 产生随机颜色
    randomColor(min, max) {
      const r = this.randomNum(min, max);
      const g = this.randomNum(min, max);
      const b = this.randomNum(min, max);
      return `rgb(${r},${g},${b})`;
    },
    //todo 绘图
    draw() {
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");
        //!! 1.填充背景颜色，背景颜色要浅一点
        ctx.fillStyle = this.randomColor(180, 230);
        // 填充的位置
        ctx.fillRect(0, 0, this.width, this.height);
        let imgCode = "";
        //!! 2.随机产生字符串，并且随机旋转
        for (let i = 0; i < 4; i++) {
          // 随机的四个字
          const text = this.allTexts[this.randomNum(0, this.allTexts.length)];
          imgCode += text;
          // 随机的字体大小
          const fontSize = this.randomNum(18, 40);
          // 字体随机的旋转角度
          const deg = this.randomNum(-30, 30);
          ctx.font = fontSize + "px Arial";
          ctx.textBaseline = "top";
          ctx.fillStyle = this.randomColor(80, 150);
          ctx.save();
          ctx.translate(30 * i + 15, 15);
          ctx.rotate((deg * Math.PI) / 180);
          ctx.fillText(text, -15 + 5, -15);
          ctx.restore();
        }
        //!! 3.随机产生5条干扰线
        for (let i = 0; i < 5; i++) {
          ctx.beginPath();
          ctx.moveTo(
            this.randomNum(0, this.width),
            this.randomNum(0, this.height)
          );
          ctx.lineTo(
            this.randomNum(0, this.width),
            this.randomNum(0, this.height)
          );
          ctx.strokeStyle = this.randomColor(180, 230);
          ctx.closePath();
          ctx.stroke();
        }
        //!! 4.随机产生40个干扰的小点
        for (let i = 0; i < 40; i++) {
          ctx.beginPath();
          ctx.arc(
            this.randomNum(0, this.width),
            this.randomNum(0, this.height),
            1,
            0,
            2 * Math.PI
          );
          ctx.closePath();
          ctx.fillStyle = this.randomColor(150, 200);
          ctx.fill();
        }
        return imgCode;
    },
  },
};
</script>