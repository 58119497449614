<template>
  <div id="app" style="overflow-y: hidden;">

    <NavBar></NavBar>
    <router-view></router-view>
    <Footer v-if="this.ismOrpc == 'PCoperation'"></Footer>
  </div>

</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      ismOrpc:''
    }
  },
  methods:{
    //判断移动端 or pc端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },

  },
  created() {
    if (this._isMobile()) {
      //手机端
      this.ismOrpc = 'Moperation'
      //设置rem
      /* window.onload = function(){
        getRem(750,100)
      };
      window.onresize = function(){
        getRem(750,100)
      }; */
      // 跳转手机页面
      // this.$router.push('mob')
    } else {
      //pc端
      this.ismOrpc = 'PCoperation'
      document.getElementsByTagName("body")[0].style.minWidth = 1000+"px";
      // this.$router.push('index')
    }
    this.$store.dispatch('setOrpc',this.ismOrpc)
    /* function getRem(pwidth,prem){
      var html = document.getElementsByTagName("html")[0];
      var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
      html.style.fontSize = oWidth/pwidth*prem + "px";
    } */
  },
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

</style>
