const state = {
  ismOrpc:'',
  navShow:0,
  active:0
}
const mutations = {
  SETORPC(state,ismOrpc){
    state.ismOrpc = ismOrpc
  },
  CHANGESHOW(state,navShow){
    state.navShow = navShow
  },
  CHANGEACTIVE(state,active){
    state.active = active
  }
}
const actions = {
  setOrpc({commit},ismOrpc){
    commit('SETORPC',ismOrpc)
  },
  changeShow({commit},navShow){
    commit('CHANGESHOW',navShow)
  },
  changeActive({commit},active){
    commit('CHANGEACTIVE',active)
  }
}
const getters = {
  
}

export default{
  state,mutations,actions,getters
}